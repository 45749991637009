import { fetchCube } from '@/utils/fetch';
import { GET_ISSUE } from '@/config/cube-routes';

const actions = {
    getInfos({ }, issueId) {
        const location = GET_ISSUE.replace('#issueId#', issueId);
        return fetchCube(location).then((data: any) => {
            return data.data;
        });
    },
};

export default {
    namespaced: true,
    actions,
}
