import { fetchCube } from '@/utils/fetch';
import {
    TIME_ENTRY_LIST_SUCCESS
} from './mutation-types';
import {
    GET_TIME_ENTRIES,
    GET_PUBLIC_HOLIDAYS,
    GET_TIME_ENTRIES_FOR_ISSUE,
    GET_TIME_ENTRIES_REPORT,
    GET_TIME_ENTRIES_REPORT_SUM,
    GET_TIME_ENTRIES_REPORT_FILE,
    MOVE_TIME_ENTRIES,
    MAP_PROFILE_TIME_ENTRIES,
    GET_TIME_ENTRIES_FOR_PROFILECHANGER,
    UPDATE_TIMEENTRY_PROFILES,
    GET_TIME_ENTRY_LOGS,
} from '@/config/cube-routes';
import * as I from '@/app/types/interfaces';
import { CUSTOM_FIELD_VERSION_TYPE_LEAVE, CUSTOM_FIELD_VERSION_TYPE_SALES } from '@/config/redmine-constants';

const getDefaultState = () => {
    return {
        timeEntries: {},
        publicHolidays: {},
    }
};
const state = getDefaultState();


let projects = {};


/**
 * Aggregated time entries structure (see also the AggTimeEntries interface):
 * - timeEntries[resourceId].dates[YYYY-MM-DD].hours
 * - timeEntries[resourceId].dates[YYYY-MM-DD].real
 * - timeEntries[resourceId].dates[YYYY-MM-DD].entries[<timeEntry>]
 * - timeEntries[resourceId].dates[YYYY-MM-DD].projects[<projectId>].project
 * - timeEntries[resourceId].dates[YYYY-MM-DD].projects[<projectId>].hours
 * - timeEntries[resourceId].projects[<projectId>].hours
 * - timeEntries[resourceId].projects[<projectId>].issues[<issueId>][YYYY-MM-DD].name
 * - timeEntries[resourceId].projects[<projectId>].issues[<issueId>][YYYY-MM-DD].hours
 * - timeEntries[resourceId].projects[<projectId>].issues[<issueId>][YYYY-MM-DD].real
 * - timeEntries[resourceId].projects[<projectId>].issues[<issueId>][YYYY-MM-DD].entries[<timeEntry>]
 */
function pushAggregatedTimeEntry(timeEntries, resourceId, entry: I.TimeEntry) {
    const date = entry.spent_on;
    const issueId = entry.issue_id;
    const projectId = entry.project_id;
    const hours = entry.hours;
    const real = +!entry.future_time;
    const sales: boolean = entry.version_type === CUSTOM_FIELD_VERSION_TYPE_SALES;
    const leaves: boolean = entry.version_type === CUSTOM_FIELD_VERSION_TYPE_LEAVE;

    if (!timeEntries.hasOwnProperty(resourceId)) {
        timeEntries[resourceId] = { dates: {}, projects: {}, sales: false };
    }
    let resource:I.AggTimeEntryResource = timeEntries[resourceId];
    //@ts-ignore |= not supported by our typescript version
    resource.sales |= sales;
    if (!resource.dates.hasOwnProperty(date)) {
        resource.dates[date] = { hours: 0, real: 1, entries: [], projects: {}, salesHours: 0, leavesHours: 0 };
    }
    /* timeEntries[resourceId].dates[YYYY-MM-DD] *******************************/
    let resourceDay = resource.dates[date];
    resourceDay.hours += hours;
    resourceDay.salesHours += sales ? hours : 0;
    resourceDay.leavesHours += leaves ? hours : 0;
    resourceDay.real &= real;
    resourceDay.entries.push(entry);
    if (!resourceDay.projects.hasOwnProperty(projectId)) {
        resourceDay.projects[projectId] = { hours: 0, project: entry.project };
    }
    /* timeEntries[resourceId].dates[YYYY-MM-DD].projects[<projectId>] *********/
    resourceDay.projects[projectId].hours += hours;

    if (!resource.projects.hasOwnProperty(projectId)) {
        resource.projects[projectId] = { issues: {}, hours: 0, sales: false };
    }
    /* timeEntries[resourceId].projects[<projectId>] ***************************/
    let project = resource.projects[projectId];
    //@ts-ignore |= not supported by our typescript version
    project.sales |= sales;
    project.hours += hours;
    if (!project.issues.hasOwnProperty(issueId)) {
        project.issues[issueId] = {};
    }
    let issue = project.issues[issueId];
    if (!issue.hasOwnProperty(date)) {
        issue[date] = { hours: 0, real: 1, name: entry.issue_name, entries: [] };
    }
    /* timeEntries[resourceId].projects[<projectId>].issues[<issueId>][YYYY-MM-DD] */
    issue[date].hours += hours;
    issue[date].real &= real;
    issue[date].entries.push(entry);
}


function uncompressTimeEntry(timeEntry): I.TimeEntry {
    const project_id = timeEntry[2];
    return {
        id: timeEntry[0],
        spent_on: timeEntry[1],
        project_id,
        issue_id: timeEntry[3],
        issue_name: timeEntry[4],
        user_id: timeEntry[5],
        hours: timeEntry[6],
        activity_id: timeEntry[7],
        comments: timeEntry[8],
        project: projects[project_id],
        start_time: timeEntry[9],
        future_time: timeEntry[10],
        version_type: timeEntry[11],
        profile_id: timeEntry[12],
        done_ratio_before: timeEntry[13],
        done_ratio_after: timeEntry[14],
        locked: timeEntry[15],
        status: timeEntry[16],
        tz: timeEntry[17],
    };
}


function uncompressTimeEntries(timeEntries): I.TimeEntry[] {
    return timeEntries.map(timeEntry => {
        return uncompressTimeEntry(timeEntry);
    });
}


function sortTimeEntries(timeEntries: I.TimeEntry[]) {
    timeEntries.sort((a, b) => {
        if (a.spent_on < b.spent_on) return -1;
        if (a.spent_on > b.spent_on) return 1;

        if (!a.start_time && b.start_time) return -1;
        if (a.start_time && !b.start_time) return 1;
        if (a.start_time && b.start_time) {
            if (a.start_time < b.start_time) return -1;
            if (a.start_time > b.start_time) return 1;
        }
        return 0;
    });
}


function aggregateTimeEntries(timeEntries) {
    let aggregatedTimeEntries = {};
    for (let i in timeEntries) {
        let entry = uncompressTimeEntry(timeEntries[i]);
        pushAggregatedTimeEntry(aggregatedTimeEntries, entry.user_id, entry);
    }

    return aggregatedTimeEntries;
}


function indexPublicHolidays(publicHolidays: I.PublicHoliday[]) {
    let publicHolidaysIndexed = {};
    for (let publicHoliday of publicHolidays) {
        if (!publicHolidaysIndexed.hasOwnProperty(publicHoliday.country)) {
            publicHolidaysIndexed[publicHoliday.country] = {};
        }
        publicHolidaysIndexed[publicHoliday.country][publicHoliday.date] = publicHoliday;
    }
    return publicHolidaysIndexed;
}


function isEmpty(obj) {
    for (var i in obj) { return false; }
    return true;
}
async function presetProjects(dispatch) {
    if (!isEmpty(projects)) return Promise.resolve(projects);
    const projectsArray = await dispatch('Project/list/getList', null, { root: true });
    projects = Object.fromEntries(projectsArray.map(p => [p.id, p]));
    // console.log(projects)
    return projects;
}


const getters = {
    timeEntries: state => state.timeEntries,
    publicHolidays: state => state.publicHolidays,
};


const actions = {

    async getList({ commit, dispatch }, params) {
        await presetProjects(dispatch);
        let promises = [
            fetchCube(GET_TIME_ENTRIES, { params }),
            dispatch('getPublicHolidays', params),
        ];
        return Promise.all(promises).then((data: any[]) => {
            const timeEntries = aggregateTimeEntries(data[0].time_entries);
            const publicHolidays = indexPublicHolidays(data[1].public_holidays);
            commit(TIME_ENTRY_LIST_SUCCESS, { timeEntries, publicHolidays });
        });
    },

    async getListArray({ dispatch }, params) {
        await presetProjects(dispatch);
        return fetchCube(GET_TIME_ENTRIES, { params }).then((data: any) => {
            let timeEntries = uncompressTimeEntries(data.time_entries);
            sortTimeEntries(timeEntries);
            return { timeEntries, issues: data.issues || [] };
        });
    },

    getPublicHolidays({ commit }, params) {
        return fetchCube(GET_PUBLIC_HOLIDAYS, { params })
    },

    getForIssue({ commit }, { issueId, passed }) {
        const uri = GET_TIME_ENTRIES_FOR_ISSUE.replace('#issueId#', issueId);
        return fetchCube(uri, { params: { passed } }).then((data: any) => {
            return data.data;
        });
    },

    getReportFile({ commit }, params) {
        return fetchCube(GET_TIME_ENTRIES_REPORT_FILE, { params, responseType: 'blob' });
    },

    getReport({ commit }, params) {
        return fetchCube(GET_TIME_ENTRIES_REPORT, { params }).then((data: any) => {
            return data.data;
        });
    },

    sumReport({ commit }, params) {
        return fetchCube(GET_TIME_ENTRIES_REPORT_SUM, { params }).then(data => data.data);
    },

    move({ commit }, { fromIssueId, toIssueId, timeEntryIds }) {
        const uri = MOVE_TIME_ENTRIES
            .replace('#fromIssueId#', fromIssueId)
            .replace('#toIssueId#', toIssueId)
            ;
        return fetchCube(uri, { method: 'PUT', data: { time_entry_ids: timeEntryIds } });
    },

    mapProfile({ commit }, { issueId, crmMapping, redmineMapping }) {
        const uri = MAP_PROFILE_TIME_ENTRIES.replace('#issueId#', issueId);
        return fetchCube(uri, {
            method: 'PUT', data: {
                crm_mapping: crmMapping,
                redmine_mapping: redmineMapping,
            }
        });
    },

    getListForProfilesChanger({ commit }, params) {
        return fetchCube(GET_TIME_ENTRIES_FOR_PROFILECHANGER, { params }).then((data: any) => {
            return data.data;
        });
    },

    updateProfiles({ }, data) {
        return fetchCube(UPDATE_TIMEENTRY_PROFILES, { method: 'PUT', data });
    },

    getLogs({ commit }, params) {
        return fetchCube(GET_TIME_ENTRY_LOGS, { params });
    },
};


const mutations = {
    [TIME_ENTRY_LIST_SUCCESS](state, { timeEntries, publicHolidays }) {
        state.timeEntries = timeEntries;
        state.publicHolidays = publicHolidays;
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
