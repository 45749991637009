import { fetch } from '@/utils/fetch';
import {
  ENUMERATION_LIST_ACTIVITIES_SUCCESS,
  LIST_ROLES_SUCCESS,
  LIST_STATUSES_SUCCESS,
} from './mutation-types';

import {
  GET_ENUMERATION_ACTIVITIES,
  GET_ROLES,
  GET_STATUSES,
} from '@/config/api-routes';

const getDefaultState = () => {
  return {
    activities: [],
    statuses: [],
    roles: [],
  }
};
const state = getDefaultState();

const getters = {
  activities: state => state.activities.filter(activity => activity.active === true),
  defaultActivity: state => state.activities.find(activity => activity.is_default === true),
  statuses: state => state.statuses,
  roles: state => state.roles,
};

const actions = {
  getActivities({ commit }) {
    if (state.activities.length) {
      return;
    }
    return fetch(GET_ENUMERATION_ACTIVITIES).then((data: any) => {
      commit(ENUMERATION_LIST_ACTIVITIES_SUCCESS, data.time_entry_activities);
    })
  },
  getStatuses({ commit }) {
    return fetch(GET_STATUSES).then((data: any) => {
      commit(LIST_STATUSES_SUCCESS, data.issue_statuses);
    })
  },
  getRoles({ commit }) {
    if (state.roles.length) return Promise.resolve(state.roles);
    return fetch(GET_ROLES).then((data:any) => {
      commit(LIST_ROLES_SUCCESS, data.roles);
    });
  },
};

const mutations = {
  [ENUMERATION_LIST_ACTIVITIES_SUCCESS](state, activities) {
    state.activities = activities;
  },
  [LIST_STATUSES_SUCCESS](state, statuses) {
    state.statuses = statuses;
  },
  [LIST_ROLES_SUCCESS](state, roles) {
    state.roles = roles;
  },
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
