<template>
  <div>
    <h3>v3.13.3 <small class="text-muted">04/05/2021</small></h3>
    <ul>
        <li>CRAM: <b>CSV+</b> accessible aux CP/DP</li>
        <li>SPA: RAF/DPP/DAD <b>calculs d'agrégations</b> modifiés</li>
        <li>Planning individuel
            <ul>
                <li><b>Tooltips</b> sur les saisies</li>
                <li>MÀJ de l'UX dans les filtres</li>
            </ul>
        </li>
        <li>Dashboard
            <ul>
                <li>Ajout des <b>boutons d'accès</b> aux PlanningGlobal/CRAM/Logs</li>
                <li>Rapport d'ano: Exclusion des <b>saisies planifiées</b> et <b>projets fermés</b></li>
                <li>Rapport d'ano: Ajout du <b>lien vers le SPA</b></li>
            </ul>
        </li>
    </ul>
    <h3>v3.13.0 <small class="text-muted">13/04/2021</small></h3>
    <ul>
        <li>Planning individuel
            <ul>
                <li><b>Mise en valeur</b> des saisies sur le projet sélectionné</li>
                <li>Ajout de <b>détail sur la tâche</b> (progression, statut, temps passé, etc.)</li>
                <li><b>Déplacement et redimensionnement</b> des saisies</li>
                <li><b>Cadenassage</b> des saisies</li>
            </ul>
        </li>
        <li>SPA: Les <b>statuts</b> et <b>versions</b> apparaissent entièrement au <b>survol</b></li>
        <li>Dashboard: <b>Impersonator</b> ajouté</li>
    </ul>
    <h3>v3.12.0 <small class="text-muted">02/04/2021</small></h3>
    <ul>
        <li>Planning individuel
            <ul>
                <li><b>Tâches triées</b> par ordre "SPA"</li>
                <li>Affichage de la <b>description</b> (formatée Markdown) au clic sur une tâche</li>
                <li><b>Lien Redmine</b> dans le modal de détail de la tâche</li>
                <li>Récupération des <b>jours fériés</b></li>
                <li>Affichage des projets avec <b>nombre de tâches</b> affectées</li>
            </ul>
        </li>
        <li>Saisie du temps
            <ul>
                <li>Amélioration de la <b>sélection des tâches</b> en arbre</li>
            </ul>
        </li>
    </ul>
    <h3>v3.11.0 <small class="text-muted">26/03/2021</small></h3>
    <ul>
        <li>Saisie du temps: <b>Refonte</b> du composant</li>
        <li>SPA: Saisie du temps <b>sans quitter l'arbre</b></li>
        <li>Planning individuel
            <ul>
                <li>Ouverture à tout le monde</li>
                <li>Amélioration de la <b>présentation</b> et du <b>responsiveness</b></li>
                <li>Saisies <b>modifiables</b></li>
                <li><b>Tâches glissables</b> sur le calendrier</li>
                <li><b>Notes</b> individuelles</li>
            </ul>
        </li>
        <li>Dashboard:
            <ul>
                <li>3 nouveaux rapports de <b>contrôles</b></li>
                <li>Ouverture des rapports à tout le monde</li>
                <li>Amélioration de la <b>présentation</b> et du <b>responsiveness</b></li>
            </ul>
        </li>
    </ul>
    <h3>v3.10.0 <small class="text-muted">19/03/2021</small></h3>
    <ul>
        <li>SPA: Retour des RAF/DPP/DAD au niveau <b>projet</b></li>
        <li>Dashboard: 3 rapports de <b>contrôles</b> en preview (admin)</li>
        <li>Planning individuel: "<b>Compagnon</b>" en preview (admin)</li>
        <li>Saisie du temps: Tâches <b>Closed & Removed</b> exclues</li>
        <li>CRAM: <b>Commentaires</b> ajoutés aux CSV</li>
    </ul>
    <h3>v3.9.0 <small class="text-muted">10/03/2021</small></h3>
    <ul>
        <li>Saisie du temps: Recherche par <b>id Redmine/DevOps</b></li>
        <li>Les congés passent <b>directement à l'étape 2</b> (à valider) à la création</li>
        <li>SPA: RAF/DPP/DAD <b>sommés sur les nœuds</b></li>
    </ul>
    <h3>v3.8.0 <small class="text-muted">26/02/2021</small></h3>
    <ul>
        <li>Gestion des congés
            <ul>
                <li>Option <b>demi-journées</b></li>
                <li>Le changement de statut En attente de <b>validation</b>/À <b>soumettre</b> modifie respectivement le <b>Réalisé</b>/<b>Planifié</b></li>
            </ul>
        </li>
        <li>APIKey
            <ul>
                <li>Entrypoint ajouté pour <b>récupérer les utilisateurs</b> (id/email pour l'interface DevOps/Redmine)</li>
                <li>Ajout du <b>statut sur les projets</b> (Entrypoint PowerApp)</li>
            </ul>
        </li>
        <li>Saisie du temps
            <ul>
                <li>Les tâches sont maintenant <b>présentées en arbre</b></li>
                <li>Blocage des <b>dates gelées</b> (sur le date picker)</li>
                <li>Désactivation du radio <b>Réalisé</b>/<b>Planifié</b> pour les congés</li>
            </ul>
        </li>
        <li>SPA
            <ul>
                <li><b>Commentaires</b> ajoutés en face des temps saisis (tooltip réalisé/planifié)</li>
                <li><b>Versions triées</b> alpha (après ouvert/fermé)</li>
            </ul>
        </li>
    </ul>
    <h3>v3.7.1 <small class="text-muted">18/02/2021</small></h3>
    <ul>
        <li><b>Ouverture du dashboard</b> à tout le monde</li>
        <li>Amélioration de la <b>gestion de congés</b></li>
        <li>Les <b>ressources cachées</b> peuvent se voir :)</li>
        <li>Nouvelle API dédiée aux connecteurs <b>PowerAutomate</b></li>
        <li>Jobs de récupération de <b>données Fitnet</b> vers fichiers</li>
        <li>Saisie du temps: <b>Estimé, réalisé, progression</b> en gras dans le panneau de droite</li>
    </ul>
    <h3>v3.7.0 <small class="text-muted">09/02/2021</small></h3>
    <ul>
        <li>SPA: Recherche des <b>tâches par id</b> Redmine/Devops & insensible casse/diacritique</li>
        <li>CRAM: <b>"Réalisé avant"</b> ajouté au CSV+ et pris en compte par les champs "avant"</li>
        <li>Dashboard: Accès ouvert à la <b>compta</b></li>
    </ul>
    <h3>v3.6.0 <small class="text-muted">22/01/2021</small></h3>
    <ul>
        <li>SPA
            <ul>
                <li>Amélioration du <b>mappeur de profils</b></li>
                <li>Suppression du <b>RAA non aggrégé</b> & suggestion de prise en compte Suivi/Recette</li>
            </ul>
        </li>
        <li>Outil de <b>gestion des congés</b> (preview)</li>
        <li>Fonction de <b>récupération</b> totale des <b>crédits</b> (ligne de commande)</li>
        <li>Communication client/serveur régulière pour <b>récupérer mises à jour</b> et éléments en attente de traitement (exemple congés à soumettre)</li>
    </ul>
    <h3>v3.5.0 <small class="text-muted">22/12/2020</small></h3>
    <ul>
        <li>SPA: <b>Mappeur de profils</b> au changement de version</li>
    </ul>
    <h3>v3.4.0 <small class="text-muted">15/12/2020</small></h3>
    <ul>
        <li>Planning
            <ul>
                <li><b>Statut</b> sur les tooltips des tâches</li>
                <li>Amélioration du <b>temps de réponse</b> sur la grille (réduction de la bande passante requise)</li>
            </ul>
        </li>
        <li>SPA: Versions ouvertes <b>sélectionnées par défaut</b></li>
        <li>CRAM: <b>CSV+</b> (Admins)</li>
    </ul>
    <h3>v3.3.0 <small class="text-muted">27/11/2020</small></h3>
    <ul>
        <li>Planning: <b>Gel des saisies</b> m-1 à partir du 10 du mois</li>
        <li>Versions/Credits
            <ul>
                <li>Les <b>DP</b> peuvent maintenant lancer une <b>récupération de crédits</b></li>
                <li>Nom de l'<b>opportunité</b> ajoutée dans la liste</li>
            </ul>
        </li>
        <li>Saisie du Temps
            <ul>
                <li><b>RAF/Progression</b> et <b>Temps/Profil</b> déplacés</li>
                <li>Bienvenue au <b>lapin</b> !</li>
                <li>Panneau d'information
                    <ul>
                        <li><b>Barres colorées</b> pour améliorer la lisibilité</li>
                        <li>Retour du <b>DAD</b></li>
                    </ul>
                </li>
            </ul>
        </li>
        <li>SPA
            <ul>
                <li><b>Raccourcis</b> de sélection sur les versions</li>
                <li>Configuration
                    <ul>
                        <li>Possibilité de lier <b>tout client</b> du CRM</li>
                        <li>Réglage de la date de <b>blocage des saisies</b></li>
                        <li>Les <b>CP</b> peuvent maintenant configurer leurs projets</li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
    <h3>v3.2.0 <small class="text-muted">10/11/2020</small></h3>
    <ul>
        <li>Refonte du calcul de la progression sur les tâches nœuds</li>
    </ul>
    <h3>v3.1.0 <small class="text-muted">05/11/2020</small></h3>
    <ul>
        <li>Planning: Les tâches liées à des <b>versions fermées</b> n'acceptent de saisie de temps</li>
        <li>Crédits: Fenêtre de <b>récupération glissante</b> sur un mois (Suppressions désactivées)</li>
        <li>Saisie du temps
            <ul>
                <li>Recherche de tâche par <b>id Redmine</b> exact</li>
                <li><b>Confirmation</b> de changement de progression</li>
                <li>Retour du <b>DPP</b> dans le panneau d'information</li>
                <li>Slider de <b>progression caché</b> en planifié</li>
                <li>Abandon des raccourcis <b>AM/PM/Full</b></li>
            </ul>
        </li>
    </ul>
    <h3>v3.0.0 <small class="text-muted">24/10/2020</small></h3>
    <ul>
        <li>Saisie du Temps
            <ul>
                <li><b>Panneau d'information</b> de progression</li>
                <li>La version n'est <b>plus modifiée</b> sur la tâche</li>
                <li><b>Profils client</b> simplifiés (avec auto-selection interne)</li>
            </ul>
        </li>
        <li>Amélioration de la <b>sécurité</b> de l'API</li>
        <li>SPA: <b>Icônes d'action</b> modifiées (ordre et lien devops)</li>
        <li>CRAM
            <ul>
                <li><b>Liste</b> web</li>
                <li><b>Filtres</b> Version, Type de version & Ressource</li>
                <li>Suppression de l'<b>agrégation</b></li>
                <li>Champs Projet et Version</li>
            </ul>
        </li>
    </ul>
    <h3>v2.22.0 <small class="text-muted">08/10/2020</small></h3>
    <ul>
        <li>Saisie du Temps
            <ul>
                <li>Affichage du <b>restant</b> sur les profils client</li>
                <li>Lien Redmine remplacé par <b>SPA</b></li>
                <li>Tâches nœud grisées</li>
            </ul>
        </li>
        <li>SPA
            <ul>
                <li>Ajout de l'option <b>Sans version</b> dans le filtre</li>
                <li>Déplacement du temps saisi
                    <ul>
                        <li><b>Sélection individuelle</b> des temps au déplacement</li>
                        <li><b>Progressions</b> des tâches source & cibles modifiables</li>
                    </ul>
                </li>
                <li>Tâches
                    <ul>
                        <li>Bouton contextuel pour voir les actions</li>
                        <li><b>Version</b> éditable</li>
                        <li>Bouton de <b>renommage</b></li>
                        <li>Lien de saisie</li>
                        <li>Modification de la <b>progression améliorée</b></li>
                    </ul>
                </li>
            </ul>
        </li>
        <li>Historique
            <ul>
                <li>Saisies: Filtre <b>type de version</b></li>
                <li>Tâches
                    <ul>
                        <li>Filtre <b>propriété</b></li>
                        <li><b>Libellés</b> des statuts et versions</li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
    <h3>v2.21.0 <small class="text-muted">22/09/2020</small></h3>
    <ul>
        <li>Saisie du temps: Blocage de saisie de réalisé sur un <b>nœud</b></li>
        <li>Historique
            <ul>
                <li>Historique des <b>tâches</b> ajouté</li>
                <li>Saisies:
                    <ul>
                        <li><b>Versions</b> ajoutées en tant que colonne & filtre</li>
                        <li><b>Réalisé</b> loggé et affiché</li>
                    </ul>
                </li>
            </ul>
        </li>
        <li>Amélioration des modals (notamment le défilement)</li>
        <li>SPA
            <ul>
                <li>Les modifications sur l'arbre sont maintenant bien <b>loggées</b> pour l'utilisateur spécifique</li>
                <li><b>Transfert du temps saisi</b> vers une autre tâche</li>
                <li>PECM soustrait du DPP</li>
            </ul>
        </li>
    </ul>
    <h3>v2.20.0 <small class="text-muted">11/09/2020</small></h3>
    <ul>
        <li>SPA
            <ul>
                <li><b>Échéancier</b> de facturation (jalons liés au projet)</li>
                <li>Affectation de <b>client</b></li>
                <li>Filtre <b>Iteration Path</b></li>
                <li>Colonne <b>DÀD</b> (Dépassement à date)</li>
            </ul>
        </li>
        <li>Saisie du temps
            <ul>
                <li><b>Chemin</b> sous les tâches dans la liste</li>
                <li>Ligne RAF/DPP affichée seulement pour les Forfait/C2S</li>
                <li>Couleurs et smileys sur le <b>DPP</b></li>
                <li>Lien de saisie</li>
                <li>Blocage saisie de réalisé avec <b>progression à 0%</b></li>
                <li>Blocage saisie de <b>temps sans estimé</b> pour Forfait/C2S</li>
            </ul>
        </li>
        <li>Planning: DPP colorisé sur le tooltip des tâches</li>
        <li>Jalons de facturation ouvert aux DP</li>
    </ul>
    <h3>v2.19.0 <small class="text-muted">02/09/2020</small></h3>
    <ul>
        <li>Affichage du <b>profil client</b> quand renseigné dans
            <ul>
                <li>Gestion des versions/crédits</li>
                <li>Saisie du temps</li>
                <li>CRAM (fallback sur le profil standard)</li>
            </ul>
        </li>
        <li>Jalons de facturation: <b>Montants</b> affichés</li>
        <li>Versions/Crédits
            <ul>
                <li>Champs de <b>remises</b> ajoutés</li>
                <li><b>TJMs</b> affichés, remises en tooltip</li>
                <li>La <b>suppression des crédits</b> du CRM supprime les VersionsCredits et vide les profils saisis</li>
            </ul>
        </li>
        <li>Projets: Tâches <b>removed exclues</b> des agrégations</li>
        <li>SPA: Projets sans version ouverte inclus dans la liste</li>
    </ul>
    <h3>v2.18.0 <small class="text-muted">27/08/2020</small></h3>
    <ul>
        <li>Projets: Triés par <b>DPP</b> descendant</li>
        <li><b>CRAM</b> basique en CSV</li>
        <li><b>Jalons de Facturation</b> récupérés de Fitnet</li>
        <li>Section projets ouverte aux CP/DP</li>
    </ul>
    <h3>v2.17.0 <small class="text-muted">05/08/2020</small></h3>
    <ul>
        <li>Jalons de facturation</li>
        <li>SPA: Même tri par défaut que Redmine (avant tri manuel)</li>
        <li>CP Adjoint est maintenant considéré comme un CP</li>
        <li>Projets: CP-A exclu du filtre CP/DP</li>
    </ul>
    <h3>v2.16.0 <small class="text-muted">23/07/2020</small></h3>
    <ul>
        <li>Saisie du temps
            <ul>
                <li>Sélection du <b>profil vendu client</b> (CRM)</li>
                <li><b>Suppressions</b> historisées</li>
            </ul>
        </li>
        <li>Versions Crédits: Bouton de <b>récupération des crédits</b></li>
        <li>Projets
            <ul>
                <li>Filtre <b>CP/DP</b> multiple</li>
                <li><b>Drill down</b> sur les versions</li>
                <li>Checkbox inclure les <b>versions fermées</b></li>
            </ul>
        </li>
    </ul>
    <h3>v2.15.0 <small class="text-muted">17/07/2020</small></h3>
    <ul>
        <li>Accès ouvert aux <b>Clients</b></li>
        <li>SPA: <b>Vue simplifiée</b> de l'arbre pour les clients sur leurs projets</li>
        <li>Historique: Ouvert aux CP/DP</li>
        <li>Projets: Date <b>MAC</b> éditable</li>
    </ul>
    <h3>v2.14.0 <small class="text-muted">09/07/2020</small></h3>
    <ul>
        <li>Arbre SPA
            <ul>
                <li><b>Recherche</b> de tâche</li>
            </ul>
        </li>
        <li>Historique
            <ul>
                <li><b>Liens</b> vers les tâches sur l'arbre</li>
                <li>Affichage des <b>100 derniers</b> logs (au lieu de 20)</li>
                <li>Nombre <b>total</b> de log en bas</li>
            </ul>
        </li>
        <li>Versions/Crédits
            <ul>
                <li>Accès ouvert aux DP</li>
                <li>Commande de récupération des crédits du CRM</li>
            </ul>
        </li>
        <li>Les permissions sont maintenant basées sur le <b>profil principal</b></li>
    </ul>
    <h3>v2.13.0 <small class="text-muted">02/07/2020</small></h3>
    <ul>
        <li>Saisie du temps
            <ul>
                <li><b>RAF</b> & <b>DPP</b> ajoutés sous les chiffres de la tâche</li>
                <li>Écart de progression diminué à 5%</li>
            </ul>
        </li>
        <li>Arbre SPA
            <ul>
                <li>Nouveau champ date <b>DRCC</b></li>
                <li><b>RAF</b> & <b>DPP</b> ajoutés</li>
                <li>Écart de progression diminué à 5%</li>
                <li>Responsiveness amélioré</li>
            </ul>
        </li>
    </ul>
    <h3>v2.12.0 <small class="text-muted">26/06/2020</small></h3>
    <ul>
        <li>Nouvelle section historique</li>
        <li>Projets
            <ul>
                <li>La <b>version type</b> filtre aussi la liste</li>
                <li>RAF & DPP exprimés en jour</li>
            </ul>
        </li>
        <li>Arbre SPA
            <ul>
                <li>Prise en compte du role <b>Chef de projet adjoint</b></li>
                <li>Aggrégations ajoutées sur <b>P.C.</b></li>
            </ul>
        </li>
    </ul>
    <h3>v2.11.1 <small class="text-muted">23/06/2020</small></h3>
    <ul>
        <li>Arbre SPA
            <ul>
                <li>Filtres appliqués sur les agrégations</li>
                <li>Les filtres ne cassent plus l'arbre</li>
                <li>Tri manuel des tâches</li>
            </ul>
        </li>
    </ul>
    <h3>v2.11.0 <small class="text-muted">18/06/2020</small></h3>
    <ul>
        <li>Arbre SPA
            <ul>
                <li>Indicateur DevOps sur les tâches</li>
                <li>Vue simplifiée</li>
            </ul>
        </li>
    </ul>
    <h3>v2.10.0 <small class="text-muted">17/06/2020</small></h3>
    <ul>
        <li>Projets: Filtre Type de version</li>
        <li>Arbre SPA
            <ul>
                <li>Checkboxes Removed replacés par filtre de statuts multiples</li>
                <li>Filtre de statuts appliqué sur les totaux</li>
                <li>Removed exclus par défaut</li>
                <li>DevOps URL utilisé pour conditionner le readonly</li>
                <li>Progression modifiable (Si pas d'enfant)</li>
            </ul>
        </li>
    </ul>
    <h3>v2.9.0 <small class="text-muted">11/06/2020</small></h3>
    <ul>
        <li>Arbre SPA
            <ul>
                <li>Commentaire ajouté dans le CSV</li>
                <li>Vendu initial éditable</li>
                <li>Les filtres sont maintenant bien appliqués sur les agrégations</li>
                <li>Filtres sur le statut Removed</li>
            </ul>
        </li>
    </ul>
    <h3>v2.8.0 <small class="text-muted">09/06/2020</small></h3>
    <ul>
        <li>Arbre SPA
            <ul>
                <li>Vue projet avec les mêmes informations que l'arbre</li>
                <li>Raccourci pour déplacer le PECC dans le Vendu</li>
                <li>Raccourci pour copier le réalisé/planifié dans l'estimé</li>
            </ul>
        </li>
        <li>Planning: La version met bien à jour la tâche</li>
    </ul>
    <h3>v2.7.0 <small class="text-muted">04/06/2020</small></h3>
    <ul>
        <li>Planning: Filtrer le temps appliqué sur les couleurs</li>
        <li>Arbre SPA
            <ul>
                <li>Suppression des tâches</li>
                <li>Modification des statuts</li>
                <li>Les modifications sont maintenant prises en compte quand le champ perd le focus</li>
                <li>Lien vers Redmine sur les tâches</li>
            </ul>
        </li>
        <li>Versions/Credits
            <ul>
                <li>Précision des jours abaissée à 0.05</li>
                <li>Filtre unique sur client, projet, opportunité, profil</li>
                <li>Pagination (chargement infini)</li>
            </ul>
        </li>
    </ul>
    <h3>v2.6.0 <small class="text-muted">29/05/2020</small></h3>
    <ul>
        <li>Arbre SPA
            <ul>
                <li>Les tooltips des leds sont maintenant bien mis à jour quand le contexte change</li>
                <li>RAA, progression et leds SWIS/MASAO ajoutés au niveau du projet</li>
            </ul>
        </li>
        <li>Versions/Credits
            <ul>
                <li>Bouton "valider et continuer"</li>
                <li>Opportunités triées par solde</li>
            </ul>
        </li>
        <li>Planning: Commerce & Filtrer le temps filtrent maintenant le temps</li>
        <li>Désactivation de la mise à jour de la version à partir de la saisie du temps (problème de dépendance bi-directionelle)</li>
    </ul>
    <h3>v2.5.0 <small class="text-muted">28/05/2020</small></h3>
    <ul>
        <li>Arbre SPA
            <ul>
                <li>Tooltips de colonnes & indicateur MASAO mis à jour</li>
                <li>Héritage de la version sur les tâches créées</li>
                <li>Tooltips sur Réalisé/Planifié</li>
                <li>Commentaire sur les tâches</li>
            </ul>
        </li>
    </ul>
    <h3>v2.4.1 <small class="text-muted">27/05/2020</small></h3>
    <ul>
        <li>Arbre SPA
            <ul>
                <li>RAA & Led SWIS/MASAO ajoutés</li>
                <li>Réorganisation des colonnes</li>
            </ul>
        </li>
    </ul>
    <h3>v2.4.0 <small class="text-muted">25/05/2020</small></h3>
    <ul>
        <li>Arbre SPA
            <ul>
                <li>Jours de version/crédit ajoutés</li>
                <li>Champs PECC & PECM ajoutés</li>
                <li>Mise à jour live des totaux avec prise en compte des filtres</li>
            </ul>
        </li>
        <li>Versions/Credits: Solde ajouté dans la liste des opportunités</li>
    </ul>
    <h3>v2.3.0 <small class="text-muted">19/05/2020</small></h3>
    <ul>
        <li>Arbre SPA
            <ul>
                <li>Filtrage par Versions & Phases</li>
                <li>Les CP/DP ont maintenant accès à l'arbre</li>
            </ul>
        </li>
    </ul>
    <h3>v2.2.0 <small class="text-muted">15/05/2020</small></h3>
    <ul>
        <li>Versions/Credits
            <ul>
                <li>Crédits CRM importés</li>
                <li>CRUD ajouté</li>
            </ul>
        </li>
        <li>Copie des temps ajoutée (Shift clic)</li>
        <li>Saisie du temps: La version est maintenant obligatoire et met à jour la tâche</li>
    </ul>
    <h3>v2.1.3 <small class="text-muted">07/05/2020</small></h3>
    <ul>
        <li>Arbre SPA
            <ul>
                <li>Possibilité de créer des tâches</li>
                <li>Le nouveau flag DevOps rend l'arbre non éditable</li>
                <li>Arbre éditable si Admin ou CP/DP sur le projet (Menu toujours visible seulement par Admin)</li>
                <li>Périmètre & Version ajoutés</li>
            </ul>
        </li>
    </ul>
    <h3>v2.1.2 <small class="text-muted">05/05/2020</small></h3>
    <ul>
        <li>Arbre SPA
            <ul>
                <li>Colonne des statuts</li>
                <li>Bouton d'actualisation</li>
                <li>Temps estimé et vendu modifiables</li>
            </ul>
        </li>
    </ul>
    <h3>v2.1.1 <small class="text-muted">01/05/2020</small></h3>
    <ul>
        <li>Arbre SPA
            <ul>
                <li>Progressions ajoutées</li>
                <li>Version CSV ajoutée</li>
            </ul>
        </li>
    </ul>
    <h3>v2.1.0 <small class="text-muted">30/04/2020</small></h3>
    <ul>
        <li>Arbre SPA
            <ul>
                <li>Temps des nœuds ajoutés</li>
                <li>Vendu aggrégé ajouté</li>
                <li>Conversion en jours</li>
            </ul>
        </li>
        <li>DevOps ID affiché dans le tooltip de la tâche</li>
        <li>Lien Teams ajouté dans la version non HTML de la description des events ical (Outlook)</li>
        <li>Type commerce affiché sous la version dans le tooltip de la tâche</li>
        <li>Saisie du temps: La tâche est maintenant trouvable par son id DevOps (id exact)</li>
    </ul>
    <h3>v2.0.0 <small class="text-muted">28/04/2020</small></h3>
    <ul>
        <li>Arbre SPA ajouté</li>
        <li>Menu ajouté</li>
        <li>Amélioration de l'UX</li>
        <li>Le filtre <b>Commerce</b> réduit aussi les projets quand <b>Filtrer le temps</b> est activé</li>
    </ul>
    <h3>v1.9.0 <small class="text-muted">17/04/2020</small></h3>
    <ul>
        <li>Déplacement des données du cube pour préparer la gestion des crédits</li>
        <li>Filtre <b>Commerce</b> ajouté</li>
        <li>Une <b>réunion Teams</b> peut maintenant être créée dans la saisie du temps</li>
        <li>Jours fériés UA 2019-2021 chargés</li>
        <li>Distinction du réalisé ajoutée dans le tooltip des cases de tâches</li>
        <li>Factorisation du calcul pour optimiser la performance du rendu de la grille</li>
        <li>Documentation en anglais ajoutée</li>
    </ul>
    <h3>v1.8.0 <small class="text-muted">09/04/2020</small></h3>
    <ul>
        <li>Ajout des jalons sur les jours de la grille (Tâches avec date d'échéance)</li>
        <li>Tooltips ajoutés sur les temps au niveau des tâches</li>
        <li>Temps saisis arrondis au centième dans les tooltips</li>
        <li>Règles métier déplacées dans Règles fonctionnelles</li>
        <li>L'affichage d'une ressource peut maintenant être désactivé depuis Redmine (Champ "Masquer RM")</li>
        <li>Les ressources UA sont identifiées par le skill "OBITIS" pour distinguer leurs jours fériés</li>
        <li>Découpage des dépendances pour optimiser la taille de l'application (moins de bande passante sollicitée)</li>
    </ul>
    <h3>v1.7.0 <small class="text-muted">01/04/2020</small></h3>
    <ul>
        <li>Saisie du temps
            <ul>
                <li>Sélection de ressources multiples (pas de lien entre les temps saisis pour l'instant)</li>
                <li>Raccourcis de sélection AM/PM/Full ajoutés</li>
                <li>Timepicker activé sur la sélection du temps passé</li>
                <li>Commentaire ajouté (et affiché dans le ical/Outlook)</li>
            </ul>
        </li>
        <li>Compression activée sur l'API pour améliorer les temps de réponse notamment pour la récupération des temps passés</li>
        <li>Positionnement du cadre de saisie plus adapté pour les petits écrans</li>
        <li>Lien DevOps ajouté sur le tooltip de la tâche, le cadre de saisie & sur les events ical (Outlook)</li>
        <li>Le temps estimé est maintenant basé sur le champ standard estimé au lieu du Vendu</li>
    </ul>
    <h3>v1.6.0 <small class="text-muted">24/03/2020</small></h3>
    <ul>
        <li>Lien des évènements du calendrier (ical) pointe maintenant sur le Resource Manager</li>
        <li>Liens Redmine ajoutés sur le tooltip de la tâche et le cadre de saisie de temps</li>
        <li>Partiellement assigné n'est plus coché par défaut</li>
        <li>Jours fériés français affichés</li>
        <li>Saisie du temps: Présélection du user connecté avec /time-entry/me/...</li>
        <li>Ajout d'une page pour lister les règles métier</li>
        <li>Réduction du nombre de requêtes pour améliorer les temps de réponse et la consommation de bande passante</li>
    </ul>
    <h3>v1.5.0 <small class="text-muted">19/03/2020</small></h3>
    <ul>
        <li>Ouverture de la saisie du temps à partir de l'URI (/time-entry/&lt;resourceId&gt;/&lt;issueId&gt;)</li>
        <li>Page <b>Patch notes & Documentation</b> ajoutée</li>
        <li>Les projets sont maintenant triés par ordre alphabétique dans les listes</li>
        <li>Saisie de temps: Projets/Versions recherchables</li>
        <li>L'utilisateur connecté apparait en premier dans les listes</li>
        <li>Le checkbox <b>Filtrer le temps</b> n'affiche maintenant que les ressources qui ont passé du temps dans la période sélectionnée</li>
        <li>Correction d'un bug empêchant les requêtes de log</li>
        <li>Modification du comportement du scroll sur la grille</li>
    </ul>
    <h3>v1.4.0 <small class="text-muted">10/03/2020</small></h3>
    <ul>
        <li>Titres et Descriptions du calendrier ical modifiés</li>
        <li>Recherche diacritic insensitive (ignorant les accents) gérée sur le filtre des ressources</li>
        <li>Tâches listées sur le tooltip des blocs de grille si un seul projet est concerné</li>
        <li>Requête des projets déplacée dans le cube</li>
        <li>Affichage des versions en préfixe des tâches dans la grille</li>
    </ul>
    <h3>v1.3.0 <small class="text-muted">05/03/2020</small></h3>
    <ul>
        <li>Aggrégation du temps saisi et estimé sur le projet</li>
        <li>Nom et date de dernière modification affichés dans le modal</li>
        <li>Saisies de temps loggées</li>
        <li>Drill down maintenant possible quand filtré sur un projet</li>
        <li>Cheat code pour écran ultra wide</li>
    </ul>
  </div>
</template>

<script>
import Ali from './AboutItem';

export default {
    name: 'AboutOld',
    components: { Ali, },
};
</script>
