import { fetchCube } from '../../../utils/fetch';
import { SET_USER_EXTRA } from '@/config/cube-routes';

const getDefaultState = () => {
  return {
  }
};
const state = getDefaultState();


const getters = {
};


const actions = {
  setExtra({ }, { userId, user }) {
    return fetchCube(SET_USER_EXTRA.replace('#userId#', userId), {
      method: 'PUT',
      data: user
    });
  },
};

const mutations = {
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
