<template>
  <li>
    <slot></slot>
    <span v-if="wi && isAdmin">
      (<a
        :href="
          'https://masao.visualstudio.com/MASAO%20-%20Resource%20Manager/_workitems/edit/' +
          wi
        " target="_blank"
        >#{{ wi }}</a
      >)
    </span>
    <img v-if="png" class="d-block img-thumbnail" :src="require(`@/img/${png}.png`)" />
    <img v-if="gif" class="d-block img-thumbnail" :src="require(`@/img/${gif}.gif`)" />
  </li>
</template>

<script>
import { mapGetters } from "vuex";
import { userIsAdmin } from "@/app/helpers";

export default {
  name: "AboutItem",
  props: ["wi", "img", "vid"],
  computed: {
    ...mapGetters({
      user: "Resource/auth/user",
    }),
    isAdmin() {
      return userIsAdmin(this.user);
    },
    png() {
      if (this.img === '') return this.wi;
      return this.img;
    },
    gif() {
      if (this.vid === '') return this.wi;
      return this.vid;
    },
  },
};
</script>
