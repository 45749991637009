import { fetchCube } from '@/utils/fetch';
import { NOTIF_CHANGE, HEART_IS_BEATING, TOGGLE_HEARTBEAT, } from './mutation-types';
import {
    GET_LEAVES,
    SET_LEAVES_STATUS,
    HEARTBEAT,
    COUNT_PROBLEMS,
    GET_PROBLEMS,
    GET_LEAVE_CURSORS,
    GET_LEAVES_COUNTERS,
    GET_MY_ENTRIES,
    GET_MY_ENTRIES_FOR_PROJECT,
    GET_MY_NUMBERS,
} from '@/config/cube-routes';
import getEnv from '@/utils/env';
import { ENABLE_HEARTBEAT } from '@/config/constants';


const getDefaultState = () => {
    return {
        versionMismatch: false,
        notif: 0,
        lastNotif: localStorage.getItem('resource_manager_last_notif'),
        ignoredNotif: false,
        stopHeartbeat: false,
    }
};


const state = getDefaultState();


function compareVersions(apiVersion, clientVersion) {
    let versionMismatch = false;
    if (apiVersion && clientVersion) {
        const apiParts = apiVersion.split('.');
        const clientParts = clientVersion.split('.');
        versionMismatch = apiParts[0] != clientParts[0] || apiParts[1] != clientParts[1];
    }
    return versionMismatch;
}


const getters = {
    versionMismatch: state => state.versionMismatch,
    notif: state => state.notif,
    lastNotif: state => state.lastNotif,
    ignoredNotif: state => state.ignoredNotif,
    stopHeartbeat: state => state.stopHeartbeat,
};


function getUserIdsString(userIds) {
    if (typeof userIds === 'object') return userIds.join(',');
    return userIds;
}


function getMyNumberUrl({ userIds, from, to }, format = 'json') {
    return GET_MY_NUMBERS
        .replace('{userIds}', getUserIdsString(userIds))
        .replace('{from}', from)
        .replace('{to}', to)
        .replace('{format}', format)
    ;
}


const actions = {

    async getLeaves({}, { mode, userId = null }) {
        const URI = GET_LEAVES.replace('#mode#', mode)
        const response = await fetchCube(URI + ( userId ? '/' + userId : '' ));
        return response.data;
    },

    setLeavesStatus({ }, data) {
        return fetchCube(SET_LEAVES_STATUS, { method: 'PUT', data });
    },

    async getLeaveCursors({}) {
        const response = await fetchCube(GET_LEAVE_CURSORS);
        return response.data;
    },

    async postLeaveCursors({}, { file, leavesToDate }) {
        const data = new FormData();
        data.append('file', file);
        const response = await fetchCube(GET_LEAVE_CURSORS + '/' + leavesToDate, { method: 'POST', data });
        return response.data;
    },

    async getLeaveCounters({}, userId = null) {
        const params = userId ? {userId} : {};
        const response = await fetchCube(GET_LEAVES_COUNTERS, {params});
        return response.data;
    },

    async heartbeat({commit}) {
        if (!ENABLE_HEARTBEAT) return;
        const response = await fetchCube(HEARTBEAT);
        const versionMismatch = compareVersions(response.data.version, getEnv('VUE_APP_VERSION'));
        const notif = response.data.notif;
        commit(HEART_IS_BEATING, {versionMismatch, notif});
    },

    /** Allows to temporarily ignore notifications */
    toggleNotif({ commit, state }, ignoredNotif) {
        const notif = state.notif;
        localStorage.setItem('resource_manager_last_notif', notif);
        commit(NOTIF_CHANGE, { notif, ignoredNotif });
    },

    stopHeartbeat({ commit, state }, stopHeartbeat) {
        if (state.stopHeartbeat === stopHeartbeat) return;
        commit(TOGGLE_HEARTBEAT, stopHeartbeat);
    },

    countProblems() {
        return fetchCube(COUNT_PROBLEMS).then(response => response.data);
    },

    getProblems({ }, { type, filter }) {
        const params = filter ? { filter } : null;
        return fetchCube(GET_PROBLEMS.replace('#type#', type), { params }).then(r => r.data);
    },

    getMyEntries({ }, { userIds, from, to }) {
        const url = GET_MY_ENTRIES
            .replace('{userIds}', getUserIdsString(userIds))
            .replace('{from}', from)
            .replace('{to}', to)
        ;
        return fetchCube(url).then(r => r.data);
    },

    getMyEntriesForProject({ }, { userIds, from, to, projectId }) {
        const url = GET_MY_ENTRIES_FOR_PROJECT
            .replace('{userIds}', getUserIdsString(userIds))
            .replace('{projectId}', projectId)
            .replace('{from}', from)
            .replace('{to}', to)
        ;
        return fetchCube(url).then(r => r.data);
    },

    getMyNumbers({ }, params) {
        const url = getMyNumberUrl(params);
        return fetchCube(url).then(r => r.data);
    },

    getMyNumbersDetails({ }, params) {
        const url = getMyNumberUrl(params, 'xlsx');
        return fetchCube(url, { responseType: 'blob' });
    },
};


const mutations = {
    [HEART_IS_BEATING](state, { versionMismatch, notif }) {
        state.versionMismatch = versionMismatch;
        state.notif = notif;
    },
    [NOTIF_CHANGE](state, { notif, ignoredNotif }) {
        state.lastNotif = notif;
        state.ignoredNotif = ignoredNotif;
    },
    [TOGGLE_HEARTBEAT](state, stopHeartbeat) {
        state.stopHeartbeat = stopHeartbeat;
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
