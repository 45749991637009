import { store } from '@/store';
import { authorizeRule } from "@/app/helpers";

function getUserAndProceed(proceed: Function) {
    if (!store.getters['Resource/auth/user']) {
        store.watch(() => store.getters['Resource/auth/user'], function () {
            proceed();
        });
    } else {
        proceed();
    }
}

export function globalBeforeEach(to, from, next) {
    if (!to.matched.length) return document.title = 'Not found';
    document.title = to.meta.title ? ('RM - ' + to.meta.title) : 'Resource Manager';
    getUserAndProceed(async () => {
        const user = store.getters['Resource/auth/user'];
        next(authorizeRule(to.matched[0].meta, user));
    });
}
