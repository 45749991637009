import { fetchCube } from '@/utils/fetch';
import { inputToTimeEntry } from '@/app/helpers';
import { CRUD_ACTION } from '@/config/cube-routes';


function crudAction(data, entity, method) {
    const uri = CRUD_ACTION.replace('#entity#', entity);
    return fetchCube(uri, { data, method });
}


const actions = {
    upsertTimeEntry({ }, timeEntryInput) {
        const timeEntry = inputToTimeEntry(timeEntryInput);
        return crudAction(timeEntry, 'time_entries', 'POST');
    },
    removeTimeEntry({ }, timeEntryInput) {
        const timeEntry = inputToTimeEntry(timeEntryInput);
        return crudAction(timeEntry, 'time_entries', 'DELETE');
    },
    upsertIssue({ }, issue) {
        return crudAction(issue, 'issues', 'POST');
    },
};


export default {
    namespaced: true,
    actions,
}
