<template>
  <div class="top_bar">
    <span>
      <clink :to="to" class="btn btn-link">&lsaquo; Back to dashboard</clink>
      <slot name="left"></slot>
    </span>
    <slot name="right">
      <Bookmarks :bookmarkable="bookmarkable" v-if="!userIsClient" />
    </slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { userIsClient } from "@/app/helpers";
import Bookmarks from "@/views/common/Bookmarks.vue";

export default {
  props: {
    to: {
      default: () => ({ name: "dashboard" }),
    },
    bookmarkable: {
      default: false,
    },
  },
  components: { Bookmarks },
  computed: {
    // @ts-ignore
    ...mapGetters({
      user: "Resource/auth/user",
    }),
    userIsClient() {
      return userIsClient(this.user);
    },
  },
};
</script>
