import getEnv from "@/utils/env";

export const FILTER_RESOURCES = 1;
export const FILTER_PROJECT = 2;
export const FILTER_SKILL_SEARCH = 3;
export const FILTER_SKILL = 5;
export const FILTER_ON_SALES = 4;

export const MOCK_USER = (getEnv('NODE_ENV') === 'development') && getEnv('VUE_APP_MOCK_USER');

/* Config */

export const SORTED_CATEGORIES_KEY = 'resource_manager_skills_ordered_categories';

export const REDMINE_API_KEY_HEADER = 'X-Redmine-API-Key';
export const REDMINE_TIMEZONE = 'Europe/Paris';

export const HEARTBEAT_FREQ_MS = 1000 * 10; // 10sec
export const INACTIVITY_TIMEOUT_MS = 1000 * 60 * 1; // 1min
export const ENABLE_HEARTBEAT = true;
export const ENABLE_SSE = true;

export const SEARCH_MIN_SKILL_LVL = 3;
export const TOOLTIP_MIN_SKILL_LVL = 3;

export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const PASSED_DAYS_BEFORE_READONLY = 500;
export const DEFAULT_LOCKED_UNTIL_DAY = 10;

export const PRIMARY_COLOR = '#316cb4';

if (getEnv('NODE_ENV') !== 'test') {
    if (!localStorage.getItem('resource_manager_calendar_days_range')) {
        localStorage.setItem('resource_manager_calendar_days_range', '30');
    }
}

export const DAYS_RANGE = (getEnv('NODE_ENV') !== 'test')
    ? parseInt(localStorage.getItem('resource_manager_calendar_days_range'))
    : 20;

export let DEFAULT_LOCKED_UNTIL = null;

export const COLOR_THEME_EARLY_LATE_ENTRY = 'earlyLateEntry';
export const COLOR_THEME_EARLY_LATE_ISSUE = 'earlyLateIssue';
export const COLOR_THEME_PROBLEMS = 'problems';
export const COLOR_THEMES = [
    {
        id: COLOR_THEME_EARLY_LATE_ENTRY,
        label: 'Early/Late Time Entry',
        gradient: 'linear-gradient(to right, hsl(0,80%,30%), hsl(60,80%,30%), hsl(120,80%,40%))',
        gradientStart: '0%',
        gradientEnd: '100%',
    },
    {
        id: COLOR_THEME_EARLY_LATE_ISSUE,
        label: 'Early/Late Task',
        gradient: 'linear-gradient(to right, hsl(0,80%,30%) 0%, hsl(60,80%,30%) 50%, hsl(120,80%,40%) 100%)',
        gradientStart: '100h',
        gradientEnd: '-100h',
    },
    // {
    //     id: COLOR_THEME_PROBLEMS,
    //     label: 'Anomalies',
    //     gradient: `linear-gradient(to right, hsl(0,80%,30%) 50%, ${PRIMARY_COLOR} 50%)`,
    //     gradientStart: 'Problem',
    //     gradientEnd: 'Good',
    // },
];

export const TREE_COLS = [
    { id: "subject", label: "Titre", readonly: true },
    { id: "status", label: "Statut" },
    { id: "version", label: "Version" },
    { id: "drcc", label: "DRCC" },
    { id: "pc", label: "PC" },
    { id: "sold", label: "Vendu" },
    { id: "estimated", label: "Estimé" },
    { id: "pecc", label: "PECC" },
    { id: "pecm", label: "PECM" },
    { id: "raa", label: "RAA" },
    { id: "s", label: "S (SWISS)" },
    { id: "passed", label: "Réalisé" },
    { id: "planned", label: "Planifié" },
    { id: "done_ratio", label: "%" },
    { id: "raf", label: "RAF" },
    { id: "dpp", label: "DPP" },
    { id: "dad", label: "DAD" },
    { id: "m", label: "M (Masao)" },
];

/**
 * Sets DEFAULT_LOCKED_UNTIL to the end of the previous month if we are before the 10th, otherwise the month before
 * Examples:
 * - We are 08/11/2020 -> 30/09/2020 will be set
 * - We are 12/11/2020 -> 31/10/2020 will be set
 */
function setLockedUntilDay() {
    let date = new Date();
    let monthOffset = 0;
    if (date.getDate() <= DEFAULT_LOCKED_UNTIL_DAY) {
        monthOffset--;
    }
    date.setDate(1);
    date.setMonth(date.getMonth() + monthOffset);
    date.setDate(0);
    const y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    const m = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    const d = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    DEFAULT_LOCKED_UNTIL = `${y}-${m}-${d}`;
}

setLockedUntilDay();
