import { fetchCube } from '@/utils/fetch';
import {
    MARKER_LIST_SUCCESS,
    MARKER_LIST_RESET,
} from './mutation-types';
import { GET_MARKERS } from '@/config/cube-routes';
import { MarkerIssue } from '@/app/types/interfaces';
import { CUSTOM_FIELD_VERSION_TYPE_SALES } from '@/config/redmine-constants';


const getDefaultState = () => {
    return {
        markers: {},
    }
};


const state = getDefaultState();


/**
 * Aggregated markers structure
 * markers[YYYY-MM-DD].type (0: standard, 1: sales, 2: mixed)
 * markers[YYYY-MM-DD].projects[projectId].name
 * markers[YYYY-MM-DD].projects[projectId].issues[<MarkerIssue>]
 */
function pushAggregatedMarker(markers, marker: MarkerIssue) {
    const date = marker.due_date;
    const projectId = marker.project_id;
    const type = marker.version_type === CUSTOM_FIELD_VERSION_TYPE_SALES ? 1 : 0;
    if (!markers.hasOwnProperty(date)) {
        markers[date] = { type, projects: {} };
    }
    if (markers[date].type !== 2 && markers[date].type !== type) markers[date].type = 2;
    if (!markers[date].projects.hasOwnProperty(projectId)) {
        markers[date].projects[projectId] = { name: marker.project_name, issues: [] };
    }
    markers[date].projects[projectId].issues.push(marker);
}


function aggregateMarkers(markers: MarkerIssue[]) {
    let aggregatedMarkers = {};
    for (let i in markers) {
        const marker = markers[i];
        pushAggregatedMarker(aggregatedMarkers, marker);
    }
    return aggregatedMarkers;
}


const getters = {
    markers: state => state.markers
};


const actions = {
    getList({ commit }, { resourceId, from, to }) {
        const uri = GET_MARKERS.replace('#resourceId#', resourceId) + `?from=${from}&to=${to}`;
        return fetchCube(uri).then((data: any) => {
            const markers = aggregateMarkers(data.markers);
            commit(MARKER_LIST_SUCCESS, markers);
            return markers;
        });
    },


    reset({ commit }) {
        commit(MARKER_LIST_RESET);
    }
};


const mutations = {
    [MARKER_LIST_SUCCESS](state, markers) {
        state.markers = markers;
    },
    [MARKER_LIST_RESET](state) {
        Object.assign(state, getDefaultState());
    }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
