import { TREE_COLS } from '@/config/constants';
import { SET_COL } from './mutation-types';

const state = {
    colSet: [],
};

const getters = {
    noColSet(state) {
        if (!state.colSet.length) return true;
        return state.colSet.length === TREE_COLS.length;
    },
    colSet(state) {
        if (!state.colSet.length) return TREE_COLS.map(col => col.id);
        const colSet = state.colSet.concat(TREE_COLS.filter(col => col.readonly).map(col => col.id));
        return colSet.filter((col, index) => colSet.indexOf(col) == index);
    },
    cia(state) {
        return TREE_COLS.reduce((acc, col) => {
            return { ...acc, [col.id]: !state.colSet.length || state.colSet.includes(col.id) };
        }, {});
    },
};

const mutations = {
    [SET_COL](state, colSet) {
        state.colSet = colSet;
    },
};

const loader = {
    namespaced: true,
    state,
    getters,
    mutations,
};

export default loader;
