import * as Msal from 'msal';

const scopes = [
    // 'User.Read',
    // 'OnlineMeetings.ReadWrite',
    'api://791fa400-46d8-4919-a4d2-cf29e22fe14d/read',
    // 'api://791fa400-46d8-4919-a4d2-cf29e22fe14d/write',
];
const msalConfig: Msal.Configuration = {
    auth: {
        clientId: '791fa400-46d8-4919-a4d2-cf29e22fe14d',
        authority: 'https://login.microsoftonline.com/1bcd12a8-3150-4dac-9b28-22fa6d053198',
        redirectUri: window.location.protocol + '//' + window.location.host,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
    }
};

let redirecting = false;

let userAgentApplication = new Msal.UserAgentApplication(msalConfig);
userAgentApplication.handleRedirectCallback((error, response) => {
    if (error) {
        console.error('MSAL handle error:', error);
        localStorage.setItem('error', 'yes');
        return;
    }
    localStorage.removeItem('error');
    // redirecting = true;
    // window.location.href = msalConfig.auth.redirectUri;
});


function loginRequired() {
    return !isAuthenticated();
}


function login() {
    if (!loginRequired()) return true;
    var requestObj = { scopes };
    userAgentApplication.loginRedirect(requestObj);
    return false;
}


export function logout() {
    userAgentApplication.logout();
}


export function getAccess(callback) {
    if (!login()) return;
    const request = { scopes };
    return userAgentApplication.acquireTokenSilent(request)
        .then(callback)
        .catch(error => {
            if (error.errorCode === "consent_required"
                || error.errorCode === "interaction_required"
                || error.errorCode === "login_required") {
                // @ts-ignore
                return userAgentApplication.acquireTokenRedirect(request).then(callback)
                    .catch(function (error) {
                        console.log(error);
                    });
            }
            console.error('[Access token error]', error);
            throw error;
        });
}


export function getProfile() {
    return getAccess(response => {
        const accessToken = response.accessToken;
        if (!accessToken) return;
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        headers.append('Authorization', bearer);

        const options = {
            method: 'GET',
            headers: headers
        };

        return fetch('https://graph.microsoft.com/v1.0/me', options)
            .then(response => response.json())
            .catch(error => {
                console.error('[Graph call me error]', error);
                throw error;
            });
    });
}


export function postMeeting(event) {
    return getAccess(response => {
        const accessToken = response.accessToken;
        if (!accessToken) return;
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');

        const onlineMeeting = {
            startDateTime: event.start,
            endDateTime: event.end,
            subject: event.subject
        };

        const options = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(onlineMeeting)
        };

        return fetch('https://graph.microsoft.com/v1.0/me/onlineMeetings', options)
            .then(response => response.json())
            .catch(error => {
                console.error('[Graph call meetings error]', error);
                throw error;
            });
    });
}


export function getAccount() {
    return userAgentApplication.getAccount();
}


export function getUsername() {
    const account = getAccount();
    if (!account) return null;
    return getAccount().userName.split('@')[0];
}


export function isAuthenticated() {
    return !!getAccount();
}


export { redirecting }
