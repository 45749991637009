<template>
  <div
    class="top_menu right"
    v-click-outside="closeMenu"
    v-if="bookmarkable || bookmarks.length"
  >
    <div class="d-flex">
      <div class="d-flex px-1 pt-2" v-if="menuOpen && bookmarkable">
        <button class="btn btn-sm d-flex align-items-center" @click="save">
          <IconAdd />
        </button>
        <input
          type="text"
          class="form-control form-control-sm"
          v-model="bookmarkName"
          placeholder="New bookmark"
          @keyup.enter="save"
          ref="bookmarkName"
        />
      </div>
      <span class="d-inline-block cpointer ml-auto" @click="toggleMenu">
        <IconBookmark class="toggler" />
      </span>
    </div>
    <div v-if="menuOpen">
      <div v-for="group in groups" :key="group.route">
        <hr />
        <div class="px-2 pt-1 text-muted">{{ group.name }}</div>
        <div class="links">
          <div
            v-for="(bookmark, index) in group.bookmarks"
            @click="goto(bookmark)"
            class="pl-1"
            :key="index"
          >
            <button
              class="btn btn-sm d-flex align-items-center"
              @click.stop="remove(bookmark)"
            >
              <IconDel />
            </button>
            <div class="mr-auto">{{ bookmark.label }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IconBookmark from "@/icons/star-solid.svg";
import IconAdd from "@/icons/plus-square-solid.svg";
import IconDel from "@/icons/minus-square-solid.svg";

export default {
  components: {
    IconBookmark,
    IconAdd,
    IconDel,
  },
  props: {
    bookmarkable: {
      default: false,
    },
  },
  data() {
    return {
      menuOpen: false,
      bookmarkName: "",
    };
  },
  computed: {
    ...mapGetters({
      bookmarks: "Resource/list/bookmarks",
      user: "Resource/auth/user",
    }),
    groups() {
      if (!this.bookmarks.length) return [];
      const router = this.$router;
      return this.bookmarks.reduce((acc, bookmark) => {
        const route = bookmark.to.name;
        const name = router.resolve(bookmark.to).resolved.meta.title;
        acc[route] = acc[route] || { route, name, bookmarks: [] };
        acc[route].bookmarks.push(bookmark);
        return acc;
      }, {});
    },
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
      this.$nextTick(() => {
        if (this.menuOpen && this.$refs.bookmarkName)
          this.$refs.bookmarkName.focus();
      });
    },
    closeMenu() {
      this.menuOpen = false;
    },
    save() {
      this.$store.dispatch("Resource/list/bookmarkSaving", this.bookmarkName);
      this.bookmarkName = "";
    },
    refresh() {
      this.$store.dispatch("Resource/list/getBookmarks");
    },
    goto(bookmark) {
      this.closeMenu();
      this.$router.replace({
        name: bookmark.to.name,
        params: bookmark.to.params,
        query: { _rnd: new Date().getTime() },
      });
    },
    remove(bookmark) {
      const index = this.bookmarks.indexOf(bookmark);
      const bookmarksObj = [...this.bookmarks];
      bookmarksObj.splice(index, 1);
      this.$store
        .dispatch("Resource/edit/setExtra", {
          userId: this.user.id,
          user: { bookmarks: JSON.stringify(bookmarksObj) },
        })
        .then(() => {
          this.$store.dispatch("Resource/list/getBookmarks");
        });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
