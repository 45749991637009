// Users
// export const GET_USERS = '/users.json?sort=firstname,lastname';
export const GET_USER = '/users/#id#.json?include=groups';

// Projects
export const GET_VERSIONS = '/projects/#projectId#/versions.json';
export const PROJECT_SETTINGS = '/projects/#projectId#/settings';
export const PUT_PROJECT = '/projects/#projectId#.json';
export const POST_PROJECT = '/projects.json';

// Issues
export const ISSUE_LINK = '/issues/#issueId#';
export const POST_ISSUE = '/issues.json';
export const PUT_ISSUE = '/issues/#issueId#.json';

// Time Entries
export const POST_TIME_ENTRY = '/time_entries.json';
export const PUT_TIME_ENTRY = '/time_entries/#id#.json';

// Enumerations
export const GET_ENUMERATION_ACTIVITIES = '/enumerations/time_entry_activities.json';
export const GET_STATUSES = '/issue_statuses.json';
export const GET_CUSTOM_FIELDS = '/custom_fields.json';
export const GET_ROLES = '/roles.json';

// Versions
export const POST_VERSION = '/projects/#id#/versions.json';
export const PUT_VERSION = '/versions/#id#.json';
