const key = 'resource_manager_impersonate';


export function impersonated() {
    return localStorage.getItem(key);
}


export function removeImpersonation() {
    localStorage.removeItem(key);
}


export function impersonate(username) {
    localStorage.setItem(key, username);
    window.location.reload();
}


export function depersonate() {
    removeImpersonation();
    window.location.reload();
}
