import {
    RESOURCE_AUTH_RESET,
    RESOURCE_AUTH_USER,
    RESOURCE_AUTH_WRONG_REDMINE_USER,
} from './mutation-types';

const getDefaultState = () => {
  return {
    profile: null,          /* Azure AD user */
    user: null,             /* Redmine user */
    wrongRedmineUser: null
  }
};
const state = getDefaultState();

const getters = {
  user: state => state.user,
  impersonated: state => state.impersonated,
  redirecting: state => state.redirecting,
  authenticated: state => state.authenticated,
  wrongRedmineUser: state => state.wrongRedmineUser,
};

const actions = {
  setWrongRedmineUser({commit}, name) {
    commit(RESOURCE_AUTH_WRONG_REDMINE_USER, name)
  },
  setResource({ commit }, user) {
    commit(RESOURCE_AUTH_USER, user);
  },
  reset({ commit }) {
    commit(RESOURCE_AUTH_RESET);
  }
};

const mutations = {
  [RESOURCE_AUTH_WRONG_REDMINE_USER](state, name) {
    state.wrongRedmineUser = name;
  },
  [RESOURCE_AUTH_USER](state, user) {
    state.user = user;
  },
  [RESOURCE_AUTH_RESET](state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
