import Vue from 'vue';
import VueRouter from 'vue-router';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import VueGtag from 'vue-gtag';
import { store } from './store';
import { router } from './router';
import * as Vadds from './utils/vue-additions';
import { loadUser } from '@/utils/user-loader';
import gtagConfig from '@/utils/gtag-config';
import * as Config from '@/config/constants';
// import getEnv from '@/utils/env';
import App from '@/views/App.vue';
import TopBar from '@/views/common/TopBar.vue';
import Clink from '@/views/common/Clink.vue';
import Checkbox from "@/views/common/Checkbox.vue";


dayjs.locale('fr');
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);


Vue.use(VueRouter);
Vue.use(VueGtag, gtagConfig, router);
Vue.directive('click-outside', Vadds.ClickOutside);
Vue.filter('toCurrency', Vadds.ToCurrency);
Vue.filter('formatPhpDate', Vadds.FormatPhpDate);
Vue.filter('formatNumber', Vadds.FormatNumber);
Vue.filter('formatHours2Days', Vadds.FormatHours2Days);
Vue.component('TopBar', TopBar);
Vue.component('Clink', Clink);
Vue.component('Checkbox', Checkbox);


if (!localStorage.getItem('resource_manager_last_notif')) {
    localStorage.setItem('resource_manager_last_notif', '0');
}

let heartbeatInterval = null;

if (window === top) {
    loadUser();
    new Vue({
        name: 'App',
        router,
        store,
        render: h => h(App),
        beforeCreate() {
            if (!Config.ENABLE_HEARTBEAT) return;
            heartbeatInterval = setInterval(() => {
                if (this.$store.getters['Dashboard/list/stopHeartbeat']) return;
                this.$store.dispatch('Dashboard/list/heartbeat');
            }, Config.HEARTBEAT_FREQ_MS);
        },
        beforeDestroy() {
            if (!Config.ENABLE_HEARTBEAT) return;
            clearInterval(heartbeatInterval);
        }
    }).$mount('#app');
}

// if (Config.ENABLE_SSE) {
//     const url = new URL(getEnv('VUE_APP_MERCURE_PUBLIC_URL'));
//     url.searchParams.append('topic', 'global');
//     //@ts-ignore
//     const eventSource = new EventSource(url);
//     eventSource.onmessage = e => console.log('Mercure broadcast message', JSON.parse(e.data));
// }
