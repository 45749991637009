import list from './list';
import create from './create';

export default {
  namespaced: true,
  modules: {
    list,
    create,
  }
};
