import { fetchCube } from '@/utils/fetch';
import {
    RESOURCE_LIST_SUCCESS,
    RESOURCE_LIST_RESET,
} from './mutation-types';
import { GET_USER_BOOKMARKS, GET_USER_NOTES, GET_USERS } from '@/config/cube-routes';
import { User } from '@/app/types/interfaces';

const getDefaultState = () => {
    return {
        resources: [],
        bookmarks: [],
        bookmarkSaving: null,
    }
};
const state = getDefaultState();

function reset() {
    return { type: RESOURCE_LIST_RESET }
}

const getters = {
    resourcesAll: state => state.resources,
    resourcesActive: state => state.resources.filter((user: User) => {
        if (user.isLogged) return true;
        return user.status === 1;
    }),
    resources: state => state.resources.filter((user: User) => {
        if (user.isLogged) return true;
        return !user.hiderm && user.status === 1;
    }),
    bookmarks: state => state.bookmarks,
    bookmarkSaving: state => state.bookmarkSaving,
};

const actions = {
    getList({ state, commit }) {
        if (state.resources.length) {
            commit(RESOURCE_LIST_SUCCESS, state.resources);
            return Promise.resolve(state.resources);
        }
        return fetchCube(GET_USERS).then((data) => {
            commit(RESOURCE_LIST_SUCCESS, data.data);
            return data.data;
        });
    },


    setOrder({ commit }, loggedUserId: number) {
        const loggedUserIndex = state.resources.map(resource => resource.id).indexOf(loggedUserId);
        const loggedResource = state.resources.splice(loggedUserIndex, 1)[0];
        loggedResource.isLogged = true;
        state.resources.splice(0, 0, loggedResource);
    },


    getNotes({ }, userId) {
        return fetchCube(GET_USER_NOTES.replace('#userId#', userId)).then((data) => {
            return typeof data.data === 'string' ? data.data : null;
        });
    },


    getBookmarks({ state }) {
        return fetchCube(GET_USER_BOOKMARKS).then(data => {
            state.bookmarks = data.data;
        });
    },


    bookmarkSaving({ state }, name) {
        if (!name) return;
        state.bookmarkSaving = name;
    },


    reset({ commit }) {
        commit(reset())
    }
};

const mutations = {
    [RESOURCE_LIST_SUCCESS](state, resources) {
        state.resources = resources;
    },
    [RESOURCE_LIST_RESET](state) {
        Object.assign(state, getDefaultState())
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
