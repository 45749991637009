import { fetchCube } from '@/utils/fetch';
import {
    CREDIT_LIST_SUCCESS,
    CREDIT_LIST_RESET,
    CREDIT_CUSTOMERS_LIST_SUCCESS,
} from './mutation-types';
import {
    GET_VERSIONS_CREDITS,
    GET_CREDITS_CUSTOMERS,
    SEARCH_CUSTOMERS,
    GET_CREDITS_OPPORTUNITIES,
    GET_CREDITS_LINES,
    EDIT_VERSION_CREDIT,
    DELETE_VERSION_CREDIT,
    GET_PROFILES_FOR_VERSION,
    RETRIEVE_CREDIT_LINES,
    RETRIEVE_CUSTOMERS,
    GET_PROFILES_USED_FOR_ISSUE,
    GET_PROFILES_USED_FOR_VERSION,
    GET_VCRS_FOR_VERSION,
    IGNORE_CREDIT,
} from '@/config/cube-routes';


const getDefaultState = () => {
    return {
        credits: [],
        customers: [],
    }
};


const state = getDefaultState();


const getters = {
    credits: state => state.credits,
    customers: state => state.customers,
};


const actions = {
    getList({ commit }, params) {
        return fetchCube(GET_VERSIONS_CREDITS, { params });
    },

    /** Retrieves only customer with Credits */
    getCustomers({ commit }) {
        if (state.customers.length) return Promise.resolve(state.customers);
        return fetchCube(GET_CREDITS_CUSTOMERS).then((data: any) => {
            const customers = data.data;
            commit(CREDIT_CUSTOMERS_LIST_SUCCESS, customers);
            return customers;
        });
    },

    searchCustomers({ commit }, search) {
        return fetchCube(SEARCH_CUSTOMERS, { params: { search } }).then((data: any) => {
            return data.data;
        });
    },

    getOpportunities({ commit }, customerId) {
        const uri = GET_CREDITS_OPPORTUNITIES.replace('#customerId#', customerId);
        return fetchCube(uri).then((data: any) => {
            return data.data;
        });
    },

    getCreditLines({ commit }, { customerId, opportunityId }) {
        const uri = GET_CREDITS_LINES
            .replace('#customerId#', customerId)
            .replace('#opportunityId#', opportunityId ? opportunityId : '')
            ;
        return fetchCube(uri).then((data: any) => {
            return data.data;
        });
    },

    getProfilesForVersion({ commit }, versionId) {
        const uri = GET_PROFILES_FOR_VERSION.replace('#versionId#', versionId);
        return fetchCube(uri).then((data: any) => {
            return { profiles: data.data, lastProfileId: data.lastProfileId };
        });
    },

    getProfilesUsedForIssue({ commit }, issueId) {
        const uri = GET_PROFILES_USED_FOR_ISSUE.replace('#issueId#', issueId);
        return fetchCube(uri).then((data: any) => {
            return data.data;
        });
    },

    getProfilesUsedForVersion({ commit }, versionId) {
        const uri = GET_PROFILES_USED_FOR_VERSION.replace('#versionId#', versionId);
        return fetchCube(uri).then(data => data.data);
    },

    getVcrsForVersion({ commit }, versionId) {
        const uri = GET_VCRS_FOR_VERSION.replace('#versionId#', versionId);
        return fetchCube(uri).then(data => data.data);
    },

    retrieveCreditLines({ commit }) {
        return fetchCube(RETRIEVE_CREDIT_LINES).then((data: any) => {
            return data.data;
        });
    },

    retrieveCustomers({ commit }) {
        return fetchCube(RETRIEVE_CUSTOMERS).then((data: any) => {
            return data.data;
        });
    },

    edit({ commit }, vcredit) {
        let idPart = vcredit.id ? ('/' + vcredit.id) : '';
        const uri = EDIT_VERSION_CREDIT.replace('/#vcreditId#', idPart);
        return fetchCube(uri, {
            method: 'POST',
            data: vcredit
        });
    },

    delete({ commit }, vcreditId) {
        const uri = DELETE_VERSION_CREDIT.replace('#vcreditId#', vcreditId);
        return fetchCube(uri, { method: 'DELETE' });
    },

    ignore({ commit }, id) {
        const uri = IGNORE_CREDIT.replace('#id#', id);
        return fetchCube(uri, { method: 'DELETE' });
    },

    reset({ commit }) {
        commit(CREDIT_LIST_RESET);
    }
};


const mutations = {
    [CREDIT_LIST_SUCCESS](state, credits) {
        state.credits = credits;
    },
    [CREDIT_CUSTOMERS_LIST_SUCCESS](state, customers) {
        state.customers = customers;
    },
    [CREDIT_LIST_RESET](state) {
        Object.assign(state, getDefaultState());
    }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
