import { fetchCube } from '@/utils/fetch';
import {
    GET_GROUPS,
    POST_GROUPS,
    PUT_GROUPS,
    DELETE_GROUPS,
    GAME_GROUPS,
    GAME_GROUPS_FUTURE,
} from '@/config/cube-routes';
import { GROUP_LIST_SUCCESS } from './mutation-types';


const getDefaultState = () => {
    return {
        groups: [],
    };
};
const state = getDefaultState();


const getters = {
    groups: state => state.groups,
};


const actions = {
    getList({ commit }, force = false) {
        if (!force && state.groups.length) {
            commit(GROUP_LIST_SUCCESS, state.groups);
            return Promise.resolve(state.groups);
        }
        return fetchCube(GET_GROUPS).then(data => {
            commit(GROUP_LIST_SUCCESS, data.data);
        });
    },
    create({ }, data) {
        return fetchCube(POST_GROUPS, {
            method: 'POST',
            data,
        });
    },
    update({ }, { groupId, group }) {
        return fetchCube(PUT_GROUPS.replace('#groupId#', groupId), {
            method: 'PUT',
            data: group,
        });
    },
    remove({ }, groupId) {
        return fetchCube(DELETE_GROUPS.replace('#groupId#', groupId), {
            method: 'DELETE'
        });
    },
    game({ }, { groupId, params }) {
        return fetchCube(GAME_GROUPS.replace('#groupId#', groupId), {
            method: 'GET',
            params,
        }).then(data => data.data);
    },
    gameFuture({ }, groupId) {
        return fetchCube(GAME_GROUPS_FUTURE.replace('#groupId#', groupId), {
            method: 'GET',
        }).then(data => data.data);
    },
};


const mutations = {
    [GROUP_LIST_SUCCESS](state, groups) {
        state.groups = groups;
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
