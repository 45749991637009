export const RESOURCE = 1;
export const PROJECT = 2;
export const ISSUE = 3;
export const FAKE = 4;

export const RESOURCE_CLASS = 'resource_row';
export const PROJECT_CLASS = 'project_row';
export const ISSUE_CLASS = 'issue_row';
export const FAKE_CLASS = 'fake_row';
export const PUBLIC_HOLIDAY_CLASS = 'public_holiday';
