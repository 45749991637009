import { fetch } from '@/utils/fetch';
import { CUSTOM_FIELDS_SUCCESS } from './mutation-types';
import { GET_CUSTOM_FIELDS } from '@/config/api-routes';
import {
    CUSTOM_FIELD_ISSUE_PHASE,
    CUSTOM_FIELD_VERSION_TYPE
} from '@/config/redmine-constants';

const getDefaultState = () => {
    return {
        customFields: [],
        phases: [],
        versionTypes: [],
    }
};
const state = getDefaultState();

const getters = {
    customFields: state => state.customFields.reduce((map, obj) => (map[obj.id] = obj, map), {}),
    phases: state => state.customFields.length ? state.customFields
        .find(cf => cf.id === CUSTOM_FIELD_ISSUE_PHASE).possible_values
        .map(possible => possible.value) : [],
    versionTypes: state => state.customFields.length ? state.customFields
        .find(cf => cf.id === CUSTOM_FIELD_VERSION_TYPE).possible_values
        .map(possible => possible.value) : [],
};

const actions = {
    getList({ commit }) {
        if (state.customFields.length) return Promise.resolve(state.customFields);
        return fetch(GET_CUSTOM_FIELDS).then((data:any) => {
            commit(CUSTOM_FIELDS_SUCCESS, data.custom_fields);
        })
    },
};

const mutations = {
    [CUSTOM_FIELDS_SUCCESS](state, customFields) {
        state.customFields = customFields;
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
