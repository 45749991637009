import { fetchCube } from '@/utils/fetch';
import {
    PROJECT_LIST_SUCCESS,
    PROJECT_LIST_FILTERED,
    PROJECT_LIST_RESET
} from './mutation-types';
import {
    GET_PROJECTS,
    GET_PROJECTS_DETAIL,
    GET_PROJECT_DETAIL,
    GET_PROJECT_TOTAL,
    GET_BILLING_TIMELINE,
    GET_PROJECT_TOTALS_CACHED,
} from '@/config/cube-routes';

const getDefaultState = () => {
    return {
        projects: [],
        projectsFiltered: [],
    }
};
const state = getDefaultState();

const getters = {
    projects: state => state.projects,
    projectsFiltered: state => state.projectsFiltered,
};

const actions = {

    getList({ commit }, noCache = false) {
        if (!noCache && state.projects.length) {
            commit(PROJECT_LIST_SUCCESS, state.projects);
            return Promise.resolve(state.projects);
        }
        return fetchCube(GET_PROJECTS).then((data: any) => {
            let projects = data.data;
            commit(PROJECT_LIST_SUCCESS, projects);
            return projects;
        });
    },

    getListForProjects({ commit }, { projectId, versionType, includeClosedVersions }) {
        let params = {}
        if (projectId) params['project_id'] = projectId;
        if (versionType) params['version_type'] = versionType;
        if (includeClosedVersions) params['include_closed_versions'] = includeClosedVersions;
        return fetchCube(GET_PROJECTS_DETAIL, { params }).then((data: any) => {
            if (!projectId) {
                commit(PROJECT_LIST_FILTERED, data.data);
            }
            return data.data;
        });
    },

    getBillingTimeline({ }, projectId) {
        return fetchCube(GET_BILLING_TIMELINE.replace('#projectId#', projectId)).then((data: any) => {
            return data.data;
        });
    },

    getDetail({ }, projectId) {
        return fetchCube(GET_PROJECT_DETAIL.replace('#projectId#', projectId)).then((data: any) => {
            return data.data;
        });
    },

    getTotals({ }, { projectId, params }) {
        return fetchCube(GET_PROJECT_TOTAL.replace('#projectId#', projectId), { params }).then(data => data.data);
    },

    getTotalsCached({ }, projectId ) {
        return fetchCube(GET_PROJECT_TOTALS_CACHED.replace('#projectId#', projectId)).then(data => data.data);
    },

    getTotalsCachedForced({ }, projectId ) {
        return fetchCube(GET_PROJECT_TOTALS_CACHED.replace('#projectId#', projectId), { params: {force: ''} }).then(data => data.data);
    },

    reset({ commit }) {
        commit(PROJECT_LIST_RESET);
    },
};

const mutations = {
    [PROJECT_LIST_SUCCESS](state, data) {
        state.projects = data;
    },
    [PROJECT_LIST_FILTERED](state, data) {
        state.projectsFiltered = data;
    },
    [PROJECT_LIST_RESET](state) {
        Object.assign(state, getDefaultState());
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
