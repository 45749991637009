









import {mapGetters} from "vuex";
import {authorizeRule} from "@/app/helpers";

export default {
    name: "Clink",
    props: ["to", "text", "activeClass"],
    computed: {
        // @ts-ignore
        ...mapGetters({
            user: "Resource/auth/user",
        }),
        matched() {
            return this.$router.resolve(this.to).resolved.matched;
        },
        visible() {
            if (!this.to) return false;
            if (!this.matched.length) return false;
            return authorizeRule(this.matched[0].meta, this.user) === true;
        },
    },
};
