export default {
    config: { id: 'G-6TFYX9ELZ4' },
    appName: 'Resource Manager',
    pageTrackerScreenviewEnabled: true,
    pageTrackerTemplate(to) {
        return {
            page_title: to.meta.title,
            page_path: to.path,
        }
    },
    params: {
        send_page_view: false
    }
}
