import {REFRESH_ROWS_AND_ITEMS} from './mutation-types';

const state = {
    gridRefreshCounter: 0,
};

const getters = {
    gridRefreshCounter: state => state.gridRefreshCounter,
};

const actions = {
    refreshRowsAndItems({commit}) {
        commit(REFRESH_ROWS_AND_ITEMS);
    }
}

const mutations = {
    [REFRESH_ROWS_AND_ITEMS](state) {
        state.gridRefreshCounter++;
    },
};

const loader = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default loader;
