import loading from './loading';
import grid from './grid';
import colSet from './col-set';

export default {
  namespaced: true,
  modules: {
    loading,
    grid,
    colSet,
  }
};
