import { MOCK_USER } from '@/config/constants';
import { store } from '@/store';
import * as MySAL from './msal';
import { impersonated } from './personate';


async function authenticateAzureUser() {
    return MySAL.getAccess(() => {
        if (!MySAL.isAuthenticated()) return;
        return MySAL.getUsername();
    });
}


async function authenticateRedmineUser(username) {
    const resources = await store.dispatch('Resource/list/getList');
    let resource = resources.find(
        resource => resource.login.toLowerCase() == username.toLowerCase()
    );
    if (resource) return resource;

    console.error(username, 'is not authorized');
    store.dispatch('Resource/auth/setWrongRedmineUser', username);
}


export async function setUserByUsername(username) {
    const resource: any = await authenticateRedmineUser(username);

    store.dispatch('Resource/list/setOrder', resource.id);
    store.dispatch('Resource/auth/setResource', resource);
}


export async function loadUser() {
    let username;
    if (MOCK_USER) {
        username = MOCK_USER;
    } else {
        username = await authenticateAzureUser();
    }
    username = impersonated() || username;
    if (!username) return;
    setUserByUsername(username);
}
