<template>
  <label :class="['custom-control', 'custom-checkbox', 'mb-0', { 'nolabel': label === null }]">
    <input
      type="checkbox"
      class="custom-control-input"
      v-model="selected"
      :value="value"
      :id="id"
      :readonly="readonly"
      :disabled="disabled"
    />
    <div class="custom-control-label text-nowrap">
      <slot>
        {{ label }}
      </slot>
    </div>
  </label>
</template>

<script>
export default {
  props: {
    value: {},
    values: {},
    label: {
      default: null,
    },
    id: {
      type: String,
    },
    readonly: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  model: { prop: "values" },
  computed: {
    selected: {
      get() {
        return this.values;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
