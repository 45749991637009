import { fetchCube, fetch } from '@/utils/fetch';
import {
    ISSUE_LIST_SUCCESS,
    VERSION_LIST_SUCCESS,
    ISSUE_LIST_RESET
} from './mutation-types';
import {
  COUNT_FOR_RESOURCE_BY_PROJECT,
  GET_ISSUES,
  PECC_TO_SOLD,
  GET_ISSUE_JOURNALS,
  ISSUE_FOR_RESOURCE,
  MULTITASKS_ISSUES,
  SEARCH_ISSUES,
} from '@/config/cube-routes';
import { GET_VERSIONS } from '@/config/api-routes';

const getDefaultState = () => {
    return {
        issues: [],
        versions: [],
    }
};
const state = getDefaultState();

const getters = {
    issues: state => state.issues,
    versions: state => state.versions,
};

function convertProjectParams({
    projectId,
    versionIds,
    statusIds,
    phases,
    iterationPath,
    includeClosed,
    includeTotal,
    advanced = true,
}) {
    const params = {};
    params['project_id'] = projectId;
    if (versionIds && versionIds.length) params['version_ids'] = versionIds.join(',');
    if (statusIds && statusIds.length) params['status_ids'] = statusIds.join(',');
    if (phases && phases.length) params['phases'] = phases.join(',');
    if (iterationPath) params['iteration_path'] = iterationPath;
    if (includeClosed) params['include_closed'] = '';
    if (includeTotal) params['include_total'] = '';
    if (advanced) params['advanced'] = '';
    return params;
}

const actions = {
    getList({ commit }, originalParams) {
        const format = originalParams.format || 'json'
        let location = GET_ISSUES.replace('#format#', format);
        let params = {};
        if (originalParams.issueIds) {
            if (originalParams.issueIds.length === 0) {
                commit(ISSUE_LIST_SUCCESS, []);
                return [];
            }
            params['issue_ids'] = originalParams.issueIds.join(',');
        } else {
          params = convertProjectParams(originalParams)
        }
        const fetchParam:any = { params };
        if (format !== 'json') fetchParam.responseType = 'blob';

        return fetchCube(location, fetchParam).then((data: any) => {
            if (format !== 'json') return data;
            if (!data) return [];
            let issues = data.issues;
            commit(ISSUE_LIST_SUCCESS, issues);
            if (originalParams.includeTotal) return { issues, total: data.total };
            return issues;
        });
    },
    peccToSold({ }, params) {
      return fetchCube(PECC_TO_SOLD, { method: 'POST', params: convertProjectParams(params) })
    },
    getVersions({ commit }, projectId) {
        const location = GET_VERSIONS.replace('#projectId#', projectId);
        return fetch(location).then((data: any) => {
            const versions = data.versions.sort((v1, v2) => {
                const s1 = v1.status === 'open';
                const s2 = v2.status === 'open';
                if (s1 > s2) return -1;
                if (s1 < s2) return 1;
                return v1.name.localeCompare(v2.name, 'fr', { sensitivity: 'base' });
            });
            commit(VERSION_LIST_SUCCESS, versions);
            return versions;
        });
    },
    getJournals({ commit }, params) {
        return fetchCube(GET_ISSUE_JOURNALS, { params });
    },
    getListForResource({}, { resourceId, params }) {
        const location = ISSUE_FOR_RESOURCE.replace('#resourceId#', resourceId);
        return fetchCube(location, { params }).then((data: any) => {
            return data.data;
        });
    },
    getListCountForResource({}, { resourceId }) {
        const location = COUNT_FOR_RESOURCE_BY_PROJECT.replace('#resourceId#', resourceId);
        return fetchCube(location).then((data: any) => {
            return data.data;
        });
    },
    search({}, params) {
        return fetchCube(SEARCH_ISSUES, { params }).then(response => response.data);
    },
    getMultiTasks({}, params) {
        return fetchCube(MULTITASKS_ISSUES, { params });
    },
    reset({ commit }) {
        commit(ISSUE_LIST_RESET);
    },
};

const mutations = {
    [ISSUE_LIST_SUCCESS](state, issues) {
        state.issues = issues;
    },
    [VERSION_LIST_SUCCESS](state, versions) {
        state.versions = versions;
    },
    [ISSUE_LIST_RESET](state) {
        Object.assign(state, getDefaultState());
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
