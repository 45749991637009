import { fetch, fetchCube } from '@/utils/fetch';
import { POST_VERSION, PUT_VERSION } from '@/config/api-routes';
import { extrudeCustomFieldsFromVersion } from '@/app/helpers';
import { GET_PROJECT_VERSIONS_USED } from '@/config/cube-routes';


const actions = {
    update({ }, { id, version }) {
        const uri = PUT_VERSION.replace('#id#', id);
        extrudeCustomFieldsFromVersion(version);
        return fetch(uri, { method: 'PUT', data: { version } });
    },
    create({ }, { id, version }) {
        const uri = POST_VERSION.replace('#id#', id);
        extrudeCustomFieldsFromVersion(version);
        return fetch(uri, { method: 'POST', data: { version } });
    },
    getUsed({ }, projectId) {
        return fetchCube(GET_PROJECT_VERSIONS_USED.replace('#projectId#', projectId)).then(data => data.data);
    },
    remove({ }, id) {
        const uri = PUT_VERSION.replace('#id#', id);
        return fetch(uri, { method: 'DELETE' });
    },
};


export default {
    namespaced: true,
    actions,
}
