import { fetchCube, upsert } from '@/utils/fetch';
import {
    GET_USERS_FOR_SKILLS,
    RENAME_SKILLS_CATEGORY,
    SKILLS,
    USERS_SKILLS,
} from '@/config/cube-routes';
import { SKILL_LIST_SUCCESS } from './mutation-types';


const getDefaultState = () => {
    return {
        skills: [],
    };
};
const state = getDefaultState();


const getters = {
    skills: state => state.skills,
};


const actions = {
    getList({ commit }) {
        return fetchCube(SKILLS).then(response => {
            commit(SKILL_LIST_SUCCESS, response.data);
        });
    },
    upsert({}, data) {
        return upsert(data, SKILLS);
    },
    remove({ }, id) {
        return fetchCube(SKILLS + '/' + id, { method: 'DELETE' });
    },
    getUsersSkills({ }, userIds) {
        return fetchCube(USERS_SKILLS + '/' + userIds.join(',')).then(response => response.data);
    },
    upsertUserSkill({}, data) {
        return upsert(data, USERS_SKILLS);
    },
    removeUserSkill({ }, id) {
        return fetchCube(USERS_SKILLS + '/' + id, { method: 'DELETE' });
    },
    renameCategory({ }, data) {
        return fetchCube(RENAME_SKILLS_CATEGORY, { method: 'PUT', data });
    },
    getUsersForSkills({ }, skills) {
        return fetchCube(GET_USERS_FOR_SKILLS + '/' + skills.join(',')).then(response => response.data);
    },
};


const mutations = {
    [SKILL_LIST_SUCCESS](state, skills) {
        state.skills = skills;
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
