import { store } from '@/store';
import axios from 'axios';
import { START_LOADING, FINISH_LOADING } from '@/store/modules/app/mutation-types';
import * as MySAL from './msal';
import * as Config from '@/config/constants';
import getEnv from './env';
import { impersonated, removeImpersonation } from './personate';


const http = axios.create({
    headers: {
        'Accept': 'application/json',
    }
});


// Loading

const startLoading = (config) => {
    if (config.url.indexOf('heartbeat') !== -1) return;
    store.commit('App/loading/' + START_LOADING);
};
const finishLoading = (config) => {
    if (config.url.indexOf('heartbeat') !== -1) return;
    store.commit('App/loading/' + FINISH_LOADING);
};

http.interceptors.request.use(config => {
    startLoading(config);
    return config;
}, error => {
    finishLoading(error.config);
    return Promise.reject(error);
});
http.interceptors.response.use(response => {
    finishLoading(response.config);
    return response.data;
}, error => {
    finishLoading(error.config);
    return Promise.reject(error);
});


// General error behavior

http.interceptors.response.use(response => response, error => {
    // Prevent heartbeats when the API returns errors
    store.dispatch('Dashboard/list/stopHeartbeat', true);
    if (error.response.status === 406) removeImpersonation();
    return error;
});


// Inactivity Check (As of network activity)

let inactivityTimeout = null;
function resetInactivityTimeout() {
    if (inactivityTimeout) clearTimeout(inactivityTimeout);
    store.dispatch('Dashboard/list/stopHeartbeat', false);
    inactivityTimeout = setTimeout(() => {
        // Stopping heartbeat after INACTIVITY_TIMEOUT_MS
        store.dispatch('Dashboard/list/stopHeartbeat', true);
    }, Config.INACTIVITY_TIMEOUT_MS);
}

http.interceptors.request.use(config => {
    if (config.url.indexOf('heartbeat') === -1) resetInactivityTimeout();
    return config;
});


// Redmine Switch User

http.interceptors.request.use(config => {
    // Only concerns Redmine
    if (config.baseURL !== getEnv('VUE_APP_REDMINE_HOST')) return config;
    if (!config.hasOwnProperty('redmineSwitchUser')) return config;
    const redmineSwitchUser = config['redmineSwitchUser'];
    let login;
    if (redmineSwitchUser === true) {
        login = store.getters['Resource/auth/user'].login;
    }
    if (typeof redmineSwitchUser === 'number') {
        login = store.getters['Resource/list/resources']
            .find(resource => redmineSwitchUser === resource.id).login;
    }
    config['headers']['X-Redmine-Switch-User'] = login;
    delete config['redmineSwitchUser'];
    return config;
});


// Cube impersonate

http.interceptors.request.use(config => {
    // Only concerns Cube
    if (config.baseURL !== getEnv('VUE_APP_CUBE_HOST')) return config;
    if (impersonated()) config.headers['X-RM-Impersonate'] = impersonated();
    return config;
});


export function fetch(route, config: any = {}) {
    config.baseURL = getEnv('VUE_APP_REDMINE_HOST');
    config.url = route;
    config.headers = config.header || {};
    config.headers[Config.REDMINE_API_KEY_HEADER] = getEnv('VUE_APP_REDMINE_API_KEY');
    return http.request(config);
}


export function fetchCube(route, config: any = {}) {
    config.baseURL = getEnv('VUE_APP_CUBE_HOST');
    config.url = route;
    config.headers = config.headers || {};
    if (Config.MOCK_USER) return http.request(config);

    return MySAL.getAccess(response => {
        const accessToken = response.accessToken;
        if (!accessToken) return;
        config.headers['Authorization'] = `Bearer ${accessToken}`;
        return http.request(config);
    });
}


export function upsert(data, entity) {
    const id = data.id || null;
    const uri = entity + (id ? ('/' + id) : '');
    const method = (id ? 'PUT' : 'POST')
    delete data.id;
    return fetchCube(uri, { method, data });
}
