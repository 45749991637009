export const CUSTOM_FIELD_ISSUE_PHASE = 5;
export const CUSTOM_FIELD_FUTURE_TIME_ID = 33;
export const CUSTOM_FIELD_FUTURE_TIME_YES = 1;
export const CUSTOM_FIELD_FUTURE_TIME_NO = 0;
export const CUSTOM_FIELD_SKILLS_ID = 34;
export const CUSTOM_FIELD_SOLD_ID = 13;
export const CUSTOM_FIELD_START_TIME = 42;
export const CUSTOM_FIELD_USER_HIDE_RM = 48;
export const CUSTOM_FIELD_TEAMS_LINK = 53;
export const CUSTOM_FIELD_VERSION_TYPE = 59;
export const CUSTOM_FIELD_USER_PROFILE = 62;
export const CUSTOM_FIELD_VERSION_TYPE_INTERNAL = 'Interne';
export const CUSTOM_FIELD_VERSION_TYPE_SALES = 'Commerce';
export const CUSTOM_FIELD_VERSION_TYPE_FLATRATE = 'Forfait';
export const CUSTOM_FIELD_VERSION_TYPE_C2S = 'C2S';
export const CUSTOM_FIELD_VERSION_TYPE_RND = 'R&D';
export const CUSTOM_FIELD_VERSION_TYPE_LEAVE = 'Congés';
export const CUSTOM_FIELD_VERSION_TYPE_FORM = 'Formation';
export const CUSTOM_FIELD_VERSION_BDC = 24;
export const CUSTOM_FIELD_PROJECT_CLIENT = 25;

export const ISSUE_LEAVE_CP = 5978;
export const ISSUE_LEAVE_ANCIEN = 6391;
export const ISSUE_LEAVE_RECUP = 6395;
export const ISSUE_LEAVE_RTT = 48846;

export const INTERNAL_VERSION_TYPES = [
    CUSTOM_FIELD_VERSION_TYPE_INTERNAL,
    CUSTOM_FIELD_VERSION_TYPE_LEAVE,
    CUSTOM_FIELD_VERSION_TYPE_FORM,
];

export const BILLABLE_VERSION_TYPE = [
    CUSTOM_FIELD_VERSION_TYPE_FLATRATE,
    CUSTOM_FIELD_VERSION_TYPE_C2S,
];

export const DPPEABLE_VERSION_TYPE = [
    CUSTOM_FIELD_VERSION_TYPE_FLATRATE,
    CUSTOM_FIELD_VERSION_TYPE_C2S,
    CUSTOM_FIELD_VERSION_TYPE_RND,
];

export const ISSUE_CUSTOM_FIELD_MAPPING = {
    phase: CUSTOM_FIELD_ISSUE_PHASE,
    sold_hours: CUSTOM_FIELD_SOLD_ID,
};
export const VERSION_CUSTOM_FIELD_MAPPING = {
    type: CUSTOM_FIELD_VERSION_TYPE,
    bdc: CUSTOM_FIELD_VERSION_BDC,
};
export const TIMEENTRY_CUSTOM_FIELD_MAPPING = {
    start_time: CUSTOM_FIELD_START_TIME,
    future_time: CUSTOM_FIELD_FUTURE_TIME_ID,
    teams_link: CUSTOM_FIELD_TEAMS_LINK,
};
export const TIMEENTRY_EXTRA_FIELDS_MAPPING = {
    profileId: 'versions_credits_id',
    status: 'status',
    done_ratio_before: 'done_ratio_before',
    done_ratio_after: 'done_ratio_after',
    tz: 'tz',
};
export const INPUT_TIMEENTRY_MAPPING = {
    issue: {
        target: 'issue_id',
        handler: (input) => input.issue.id,
    },
    project: {
        target: 'project_id',
        handler: (input) => input.project.id,
    },
    activityId: {
        target: 'activity_id',
        handler: (input) => input.activityId || ACTIVITY_CONSULTANT,
    },
    comment: {
        target: 'comments',
    },
    futureTime: {
        target: 'future_time',
        handler: (input) => input.futureTime == 1 ? CUSTOM_FIELD_FUTURE_TIME_YES : CUSTOM_FIELD_FUTURE_TIME_NO,
    },
    startTime: {
        target: 'start_time',
    },
    tz: {
        target: 'tz',
        handler: (input) => input.startTime ? Intl.DateTimeFormat().resolvedOptions().timeZone : null,
    },
    date: {
        target: 'spent_on',
    },
    user: {
        target: 'user_id',
        handler: (input) => input.user.id,
    },
    doneRatioBefore: {
        target: 'done_ratio_before',
        handler: (input) => input.doneRatioBefore !== null ? input.doneRatioBefore : (input.doneRatio !== undefined ? input.doneRatio : null),
    },
    doneRatio: {
        target: 'done_ratio_after',
        handler: (input) => input.doneRatio !== undefined ? input.doneRatio : null,
    },
};

export const ACTIVITY_CONSULTANT = 8;
export const ACTIVITY_PROJECT_MANAGER = 9;
export const ACTIVITY_PROJECT_DIRECTOR = 10;
export const ACTIVITY_EXPERT = 11;
export const ROLE_CONSULTANT = 4;
export const ROLE_PROJECT_MANAGER = 3;
export const ROLE_PROJECT_MANAGER_ASSISTANT = 8;
export const ROLE_PROJECT_DIRECTOR = 7;
export const ROLE_EXTERNAL_PM = 7;
export const ROLE_ACTIVITY_MAPPING = {
    [ROLE_CONSULTANT]: ACTIVITY_CONSULTANT,
    [ROLE_PROJECT_MANAGER]: ACTIVITY_PROJECT_MANAGER,
    [ROLE_PROJECT_MANAGER_ASSISTANT]: ACTIVITY_PROJECT_MANAGER,
    [ROLE_PROJECT_DIRECTOR]: ACTIVITY_PROJECT_DIRECTOR
};
export const PROFILE_CONSULTANT = 'Consultant';
export const PROFILE_EXPERT = 'Tech Lead';
export const PROFILE_PROJECT_MANAGER = 'CP';
export const PROFILE_PROJECT_MANAGER_ASSISTANT = 'CP Adjoint';
export const PROFILE_PROJECT_DIRECTOR = 'DP';
export const PROFILE_PRESALES = 'PreSales';
export const PROFILE_SALES = 'Sales';
export const PROFILE_SUPPORT = 'Support';
export const PROFILE_CLIENT = 'Client';
export const DEFAULT_ACTIVITY = ACTIVITY_CONSULTANT;
export const DEFAULT_ISSUE_COMMENT = 'resource manager';
export const ISSUE_STATUS_NEW = 1;
export const ISSUE_STATUS_ACTIVE = 2;
export const ISSUE_STATUS_CLOSED = 3;
export const ISSUE_STATUS_ANALYSIS = 7;
export const ISSUE_STATUS_REMOVED = 8;
export const ISSUE_STATUS_RESOLVED = 9;
export const ISSUE_STATUS_DELIVERY = 10;

export const MASAO_ADMIN_PROJECT = 82;

export const ISSUE_STATUSES = [
    ISSUE_STATUS_NEW,
    ISSUE_STATUS_ACTIVE,
    ISSUE_STATUS_CLOSED,
    ISSUE_STATUS_ANALYSIS,
    ISSUE_STATUS_REMOVED,
    ISSUE_STATUS_RESOLVED,
    ISSUE_STATUS_DELIVERY,
]

export const DEFAULT_STATUSES = ISSUE_STATUSES.filter(status => status !== ISSUE_STATUS_REMOVED);

export const DEFAULT_STATUSES_FOR_ASSIGNMENT = [
    ISSUE_STATUS_NEW,
    ISSUE_STATUS_ACTIVE,
    // ISSUE_STATUS_CLOSED,
    ISSUE_STATUS_ANALYSIS,
    // ISSUE_STATUS_REMOVED,
    // ISSUE_STATUS_RESOLVED,
    // ISSUE_STATUS_DELIVERY,
];

export const ISSUE_STATUS_LABELS = {
    [ISSUE_STATUS_NEW]: 'New',
    [ISSUE_STATUS_ACTIVE]: 'Active',
    [ISSUE_STATUS_CLOSED]: 'Closed',
    [ISSUE_STATUS_ANALYSIS]: 'Analysis',
    [ISSUE_STATUS_REMOVED]: 'Removed',
    [ISSUE_STATUS_RESOLVED]: 'Resolved',
    [ISSUE_STATUS_DELIVERY]: 'Delivery',
}
export const ISSUE_STATUSES_ID_LABEL = Object.entries(ISSUE_STATUS_LABELS).map(([id, name]) => ({ id: parseInt(id), name }));

export const ISSUE_FIELD_LABELS = {
    done_ratio: 'Progression',
    status_id: 'Statut',
    estimated_hours: 'Estimé',
    subject: 'Sujet',
    description: 'Description',
    parent_id: 'Parent',
    fixed_version_id: 'Version',
    assigned_to_id: 'Assigné à',
    start_date: 'Début',
    due_date: 'Échéance',
    priority_id: 'Priorité',
    peccHours: 'PECC',
    pecmHours: 'PECM',
};

export const ADMINISTRATIVE_PROJECT = 82;

// This should be fetched from Redmine (enumeration)
export const ISSUE_PRIORITIES = [
    { id: 5, devops: '1', redmine: 'Immédiat' },
    { id: 4, devops: '2', redmine: 'Urgent' },
    { id: 3, devops: '3', redmine: 'Haut' },
    { id: 2, devops: '4', redmine: 'Normal' },
    { id: 1, devops: '5', redmine: 'Bas' },
];
