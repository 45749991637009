import { fetch, fetchCube } from '../../../utils/fetch';
import { PUT_ISSUE, POST_ISSUE } from '@/config/api-routes';
import { SET_ISSUE_EXTRA, SET_ISSUE_ORDER } from '@/config/cube-routes';
import { extrudeCustomFieldsFromIssue } from '@/app/helpers';

const getDefaultState = () => {
  return {
    showModal: false,
  }
};
const state = getDefaultState();


const getters = {
  showModal: state => state.showModal,
};


const actions = {
  create({ commit }, issue) {
    extrudeCustomFieldsFromIssue(issue);
    return fetch(POST_ISSUE, {
      method: 'POST',
      data: { issue },
      redmineSwitchUser: true
    });
  },
  update({ commit }, { issueId, issue }) {
    extrudeCustomFieldsFromIssue(issue);
    return fetch(PUT_ISSUE.replace('#issueId#', issueId), {
      method: 'PUT',
      data: { issue },
      redmineSwitchUser: true
    });
  },
  delete({ commit }, issueId) {
    return fetch(PUT_ISSUE.replace('#issueId#', issueId), {
      method: 'DELETE',
    });
  },
  setExtra({ commit }, { issueId, issue }) {
    return fetchCube(SET_ISSUE_EXTRA.replace('#issueId#', issueId), {
      method: 'PUT',
      data: issue
    });
  },
  setOrder({ commit }, issueIds) {
    return fetchCube(SET_ISSUE_ORDER, {
      method: 'PUT',
      data: { issue_ids: issueIds }
    });
  }
};

const mutations = {
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
