import {START_LOADING, FINISH_LOADING} from './mutation-types';

const state = {
  loading: false,
  count: 0
};

const getters = {
  loading: state => state.loading,
};

const mutations = {
  [START_LOADING] (state) {
    state.count++;
    state.loading = true;
  },
  [FINISH_LOADING] (state) {
    state.count--;
    if (state.count === 0) {
      state.loading = false;
    }
  }
};

const loader = {
  namespaced: true,
  state,
  getters,
  mutations
};

export default loader;
