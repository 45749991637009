import { fetchCube } from '@/utils/fetch';
import {
    BILLING_MARKER_LIST_SUCCESS,
    BILLING_MARKER_LIST_RESET,
} from './mutation-types';
import {
    GET_BILLING_MARKERS,
    EDIT_BILLING_MARKERS,
    DELETE_BILLING_MARKERS,
    RETRIEVE_BILLING_MARKERS,
} from '@/config/cube-routes';


const getDefaultState = () => {
    return {
        billingMarkers: [],
    }
};


const state = getDefaultState();


const getters = {
    billingMarkers: state => state.billingMarkers,
};


const actions = {
    getMarkers({ commit }, search) {
        const uri = GET_BILLING_MARKERS;
        const params = search ? { search } : {};
        return fetchCube(uri, { params }).then((data: any) => {
            const billingMarkers = data.data;
            commit(BILLING_MARKER_LIST_SUCCESS, billingMarkers);
            return billingMarkers;
        });
    },

    edit({ commit }, bmarker) {
        let idPart = bmarker.id ? ('/' + bmarker.id) : '';
        const uri = EDIT_BILLING_MARKERS.replace('/#bmarkerId#', idPart);
        return fetchCube(uri, {
            method: 'POST',
            data: bmarker
        });
    },

    delete({ commit }, bmarkerId) {
        const uri = DELETE_BILLING_MARKERS.replace('#bmarkerId#', bmarkerId);
        return fetchCube(uri, { method: 'DELETE' });
    },

    retrieve({ commit }) {
        return fetchCube(RETRIEVE_BILLING_MARKERS).then((data: any) => data.data);
    },

    reset({ commit }) {
        commit(BILLING_MARKER_LIST_RESET);
    }
};


const mutations = {
    [BILLING_MARKER_LIST_SUCCESS](state, billingMarkers) {
        state.billingMarkers = billingMarkers;
    },
    [BILLING_MARKER_LIST_RESET](state) {
        Object.assign(state, getDefaultState());
    }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
