import * as Config from "@/config/constants";
import dayjs from "dayjs";


export const ClickOutside = {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
};


export function ToCurrency(value) {
    if (typeof value !== 'number') {
        return value;
    }
    var formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0
    });
    return formatter.format(value);
}


export function FormatPhpDate(value) {
    return dayjs(value.date).format(Config.DATE_FORMAT);
}


export function FormatNumber(num) {
    if (!num) return '';
    num = typeof num !== 'string' ? num.toFixed(2) : num;
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function FormatHours2Days(num) {
    if (!num) return '';
    return FormatNumber(num / 8);
}
