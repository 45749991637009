import { fetchCube } from '../../../utils/fetch';
import {
    TIME_ENTRY_CREATE_RESET,
    TIME_ENTRY_CREATE_GRID_SELECTION,
    TIME_ENTRY_CREATE_SET_TIMEENTRYTOCOPY,
    TIME_ENTRY_GET_LOGS,
} from './mutation-types';

import {
    GET_LOG_TIME_ENTRY,
    // GET_LOG_TIME_ENTRY_UPDATE,
} from '@/config/cube-routes';


const getDefaultState = () => {
    return {
        gridSelection: null,
        timeEntries: [],
        timeEntryToCopy: null,
        logs: [],
        logsCount: 0,
    }
};
const state = getDefaultState();


const getters = {
    gridSelection: state => state.gridSelection,
    showModal: state => state.showModal,
    timeEntries: state => state.timeEntries,
    timeEntryToCopy: state => state.timeEntryToCopy,
    logs: state => state.logs,
    logsCount: state => state.logsCount,
};

const actions = {
    gridSelect({ commit }, selection) {
        commit(TIME_ENTRY_CREATE_GRID_SELECTION, selection);
    },
    copy({ commit }, timeEntry) {
        commit(TIME_ENTRY_CREATE_SET_TIMEENTRYTOCOPY, timeEntry);
    },
    // getLogs({ commit }, params) {
    //     return fetchCube(GET_LOG_TIME_ENTRY_UPDATE, { params })
    //         .then((data: any) => {
    //             commit(TIME_ENTRY_GET_LOGS, { logs: data.data, count: data.total });
    //         });
    // },
    getLog({ }, id) {
        const url = GET_LOG_TIME_ENTRY.replace('#id#', id);
        return fetchCube(url).then(data => data.data);
    },
    reset({ commit }) {
        commit(TIME_ENTRY_CREATE_RESET);
    },
};

const mutations = {
    [TIME_ENTRY_CREATE_RESET](state) {
        Object.assign(state, getDefaultState());
    },
    [TIME_ENTRY_CREATE_GRID_SELECTION](state, selection) {
        state.gridSelection = selection;
    },
    [TIME_ENTRY_CREATE_SET_TIMEENTRYTOCOPY](state, payload) {
        state.timeEntryToCopy = payload;
    },
    [TIME_ENTRY_GET_LOGS](state, { logs, count }) {
        state.logs = logs;
        state.logsCount = count;
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
