import { fetchCube } from '@/utils/fetch';
import {
    COUNT_ISSUE_BY_STATUS,
    GET_KPI_SLA_SUPPORT,
    GET_KPI_LATEST_ISSUES,
    GET_KPI_SLA_RESPONSE,
    GET_KPI_VCRS,
    GET_KPI_VCR_VS_SPENT,
    GET_KPI_SLA_RESPONSE_DETAIL,
} from '@/config/cube-routes';


const getDefaultState = () => {
    return {
    }
};


const state = getDefaultState();


const getters = {
};


const actions = {
    getIssuesByStatus({ }, versionIds) {
        return fetchCube(COUNT_ISSUE_BY_STATUS, { params: { versionIds } }).then(data => data.data);
    },
    getSlaSupport({ }, versionIds) {
        return fetchCube(GET_KPI_SLA_SUPPORT, { params: { versionIds } }).then(data => data.data);
    },
    getSlaResponse({ }, versionIds) {
        return fetchCube(GET_KPI_SLA_RESPONSE, { params: { versionIds } }).then(data => data.data);
    },
    getSlaResponseDetail({ }, versionIds) {
        return fetchCube(GET_KPI_SLA_RESPONSE_DETAIL, { params: { versionIds }, responseType: 'blob' });
    },
    getVcrs({ }, versionIds) {
        return fetchCube(GET_KPI_VCRS, { params: { versionIds } }).then((data) => data.data);
    },
    getVcrVsSpent({ }, versionIds) {
        return fetchCube(GET_KPI_VCR_VS_SPENT, { params: { versionIds } }).then((data) => data.data);
    },
    getLatestIssues({ }, versionIds) {
        return fetchCube(GET_KPI_LATEST_ISSUES, { params: { versionIds } }).then((data) => data.data);
    },
};


const mutations = {
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
