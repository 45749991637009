import list from './list';
import edit from './edit';
import show from './show';

export default {
  namespaced: true,
  modules: {
    list,
    edit,
    show,
  }
};
