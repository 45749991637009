import { fetchCube } from '@/utils/fetch';
import { PROJECT_MEMBERS, REMOVE_PROJECT_MEMBER } from '@/config/cube-routes';

const actions = {
    list({}, id) {
        const uri = PROJECT_MEMBERS.replace('#projectId#', id);
        return fetchCube(uri).then((data) => data.data);
    },
    add({ }, { projectId, data }) {
        const uri = PROJECT_MEMBERS.replace('#projectId#', projectId);
        return fetchCube(uri, { method: 'POST', data });
    },
    remove({ }, id) {
        const uri = REMOVE_PROJECT_MEMBER.replace('#id#', id);
        return fetchCube(uri, { method: 'DELETE' });
    },
};


export default {
    namespaced: true,
    actions,
}
