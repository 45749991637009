import {
  PROJECT_SELECTED_SUCCESS,
  PROJECT_RESET,
  AGGREGATE_ON_PROJECT,
  FILTER_ON_SALES,
} from './mutation-types';

const getDefaultState = () => {
  return {
    project: null,
    aggregateOnProject: true,
    filterOnSales: false,
  }
};
const state = getDefaultState();

const getters = {
  project: state => state.project,
  aggregateOnProject: state => state.aggregateOnProject,
  filterOnSales: state => state.filterOnSales,
};

const actions = {
  setProject({ commit }, payload) {
    if (payload.setProject) {
      commit(PROJECT_SELECTED_SUCCESS, payload.project);
    }
  },
  setAggregateOnProject({ commit }, aggregateOnProject) {
    commit(AGGREGATE_ON_PROJECT, aggregateOnProject);
  },
  setFilterOnSales({ commit }, filterOnSales) {
    commit(FILTER_ON_SALES, filterOnSales);
  },
  reset({ commit }) {
    commit(PROJECT_RESET);
  }
};

const mutations = {
  [PROJECT_SELECTED_SUCCESS](state, project) {
    state.project = project;
  },
  [AGGREGATE_ON_PROJECT](state, aggregateOnProject) {
    state.aggregateOnProject = aggregateOnProject;
  },
  [FILTER_ON_SALES](state, filterOnSales) {
    state.filterOnSales = filterOnSales;
  },
  [PROJECT_RESET](state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
