export const TIME_ENTRY_LIST_SUCCESS = 'TIME_ENTRY_LIST_SUCCESS';
export const TIME_ENTRY_LIST_RESET = 'TIME_ENTRY_LIST_RESET';

export const TIME_ENTRY_GET_LOGS = 'TIME_ENTRY_GET_LOGS';

export const TIME_ENTRY_CREATE_RESET = 'TIME_ENTRY_CREATE_RESET';
export const TIME_ENTRY_CREATE_GRID_SELECTION = 'TIME_ENTRY_CREATE_GRID_SELECTION';
export const TIME_ENTRY_CREATE_SHOW_MODAL = 'TIME_ENTRY_CREATE_SHOW_MODAL';
export const TIME_ENTRY_CREATE_SET_DATE = 'TIME_ENTRY_CREATE_SET_DATE';
export const TIME_ENTRY_CREATE_SET_STARTTIME = 'TIME_ENTRY_CREATE_SET_STARTTIME';
export const TIME_ENTRY_CREATE_SET_RESOURCE = 'TIME_ENTRY_CREATE_SET_RESOURCE';
// export const TIME_ENTRY_CREATE_SET_VERSION = 'TIME_ENTRY_CREATE_SET_VERSION';
export const TIME_ENTRY_CREATE_SET_PROJECT = 'TIME_ENTRY_CREATE_SET_PROJECT';
export const TIME_ENTRY_CREATE_SET_ISSUE = 'TIME_ENTRY_CREATE_SET_ISSUE';
export const TIME_ENTRY_CREATE_SET_DONERATIO = 'TIME_ENTRY_CREATE_SET_DONERATIO';
export const TIME_ENTRY_CREATE_SET_HOURS = 'TIME_ENTRY_CREATE_SET_HOURS';
export const TIME_ENTRY_CREATE_SET_ACTIVITY = 'TIME_ENTRY_CREATE_SET_ACTIVITY';
export const TIME_ENTRY_CREATE_SET_PROFILE = 'TIME_ENTRY_CREATE_SET_PROFILE';
export const TIME_ENTRY_CREATE_SET_COMMENT = 'TIME_ENTRY_CREATE_SET_COMMENT';
export const TIME_ENTRY_CREATE_SET_FUTURETIME = 'TIME_ENTRY_CREATE_SET_FUTURETIME';
export const TIME_ENTRY_CREATE_SET_TEAMSLINK = 'TIME_ENTRY_CREATE_SET_TEAMSLINK';
export const TIME_ENTRY_CREATE_SET_TIMEENTRYTOCOPY = 'TIME_ENTRY_CREATE_SET_TIMEENTRYTOCOPY';
