import { fetchCube, fetch } from '@/utils/fetch';
import { SET_PROJECT_EXTRA } from '@/config/cube-routes';
import { POST_PROJECT, PUT_PROJECT } from '@/config/api-routes';

const getDefaultState = () => {
  return {
    showModal: false,
  }
};
const state = getDefaultState();


const getters = {
  showModal: state => state.showModal,
};


const actions = {
  update({ commit }, { projectId, project }) {
    return fetch(PUT_PROJECT.replace('#projectId#', projectId), {
      method: 'PUT',
      data: { project },
      redmineSwitchUser: true
    });
  },
  setExtra({ commit }, { projectId, project }) {
    return fetchCube(SET_PROJECT_EXTRA.replace('#projectId#', projectId), {
      method: 'PUT',
      data: project
    });
  },
  create({ commit }, project) {
    return fetch(POST_PROJECT, {
      method: 'POST',
      data: { project }
    }).then((data: any) => data.project);
  },
};


const mutations = {
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
