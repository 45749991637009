import list from './list';
import show from './show';
import edit from './edit';
import version from './version';
import member from './member';

export default {
  namespaced: true,
  modules: {
    list,
    show,
    edit,
    version,
    member,
  }
};
