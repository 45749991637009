import list from './list';
import customFields from './custom-fields';

export default {
  namespaced: true,
  modules: {
    list,
    customFields,
  }
};
